label {
  @include font($font);
  &[for] {
    cursor: pointer;
  }
}

.input {
  &-group {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &-addon {
      padding: remc(6px) remc(12px);
      & > select {
        border-radius: 0;
        height: remc(42px);
        border: 0 none transparent;
        padding: remc(6px) remc(12px);
        margin: remc(-8px) remc(-12px);
      }
    }
  }
}

.form {
  &-group {
    margin-bottom: remc(8px);
    .form-text {
      font-size: 12px;
      line-height: 1em;
      &.form-error {
        color: cssvar($color, danger);
      }
    }
    &:not(.notValid) {
      // margin-bottom: remc(25px);
      .form-text.form-error {
        opacity: 0;
      }
    }
    &.notValid {
      .form-control,
      .SumoSelect .CaptionCont {
        border-color: cssvar($color, danger);
      }

      .form-text.form-error {
        opacity: 1;
      }
    }
  }
  &-control {
    height: 38px;
    font-size: 16px;
    border-radius: 0;
    @include font($fontL);
    color: cssvar($theme, text);
    padding: remc(5px) remc(15px);
    border: 1px solid cssvar($theme, brdr);
    // @include placeholder {
    //   color: #8f8e8e;
    // }
    &-static {
      @include font($fontB);
    }
    &:focus-visible {
      text-shadow: none;
    }
  }
  .SumoSelect {
    height: 50px;
    font-size: 18px;
    border-radius: 2px;
    &.open {
      box-shadow: 0 0 0 0.2rem cssvar($theme 0.25, color);
    }
    & > .optWrapper,
    & > .CaptionCont {
      // box-shadow: none;
      border-color: transparent;
      background: cssvar($theme, gray);
      & > .options {
        li.opt {
          padding: 4px 20px;
        }
      }
    }
    & > .CaptionCont {
      padding: 4px 4px 4px 20px;
      & > label {
        width: 30px;
        & > i {
          font-size: 30px;
        }
      }
    }
    &.accent,
    &.required {
      & > .optWrapper,
      & > .CaptionCont {
        border-left-color: cssvar($theme, accent);
      }
    }
    & > .CaptionCont {
      &,
      & > span {
        min-height: 42px;
        line-height: 42px;
      }
    }
    & > .optWrapper {
      border-radius: 0;
      top: calc(100% - 1px);
    }
  }
}

.copyText {
  position: relative;
  input {
    padding: 0;
    width: 100%;
    padding: 8px 10px;
    padding-right: 2em;
    border-radius: 5px;
    text-overflow: ellipsis;
    background: cssvar($color 0.16, accent);
    border: 1px solid cssvar($color 0.3, accent);
    &,
    &:hover,
    &:focus,
    &:active {
      outline: 0 none;
    }
  }
  .ico {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: stretch;
    button {
      width: 2em;
      height: 100%;
      display: block;
      background: transparent;
      border: 0 none transparent;
    }
  }
}

// .bootstrap-datetimepicker-widget .datepicker {
//   width: 100%;
//   &:before {
//     display: none;
//   }
// }

// .rating {
//   span.star {
//     color: rgba(#929292, 0.5);
//     &.active,
//     &.active ~ span.star {
//       color: #f7ae00;
//     }
//   }
// }

// .selectBox {
//   position: relative;
//   &:after,
//   &:before {
//     top: 0;
//     right: 0;
//     content: "";
//     width: remc(32px);
//     position: absolute;
//     text-align: center;
//     pointer-events: none;
//   }
//   &:before {
//     top: 50%;
//     z-index: 1;
//     height: 1em;
//     line-height: 1em;
//     margin-top: -0.5em;
//     content: $i-arrow_drop_down;
//     font-family: $icomoon-font-family;
//   }
//   &:after {
//     bottom: 0;
//     background: #fff;
//   }
//   & > select {
//     width: 100%;
//     height: 100%;
//     border: 0 none;
//     padding: remc(5px) remc(32px) remc(5px) remc(5px);
//   }
// }
