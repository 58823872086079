/* Suryadarsan Framework
/* @version: 4.0.0
/* @date<dd/mm/yyyy>: 07/04/2024
/*/

$tags:
  "m" "margin",
  "p" "padding";

$sides:
  "a" "",
  "t" "-top",
  "l" "-left",
  "r" "-right",
  "b" "-bottom",
  "x" "-left" "-right",
  "y" "-top" "-bottom";

$space: 5;

// FONT
.f {
  &-ultralight {
    @include font($fontUL);
  }
  &-light {
    @include font($fontL);
  }
  &-thin {
    @include font($fontT);
  }
  &-regular {
    @include font($font);
  }
  &-medium {
    @include font($fontM);
  }
  &-semibold {
    @include font($fontSB);
  }
  &-bold {
    @include font($fontB);
  }
  &-heavy {
    @include font($fontH);
  }
  &-black {
    @include font($fontBK);
  }
}

.bg {
  @each $key, $clr in $color {
    // @debug type-of($clr), $clr;
    @if (type-of($clr) != map) {
      &-#{$key} {
        & {
          --bs-bg-opacity: 1;
          --app-color-opacity: 1;
        }
        background-color: rgba(var(--bs-#{$key}-rgb), var(--bs-bg-opacity)) !important;
        color: rgba(var(--app-color-#{$key}-rgb), var(--app-color-opacity, 1)) !important;
        &._lighten {
          --bs-#{$key}-bg-rgb: #{toRGBList(map-get($color, lighten, $key))};
        }
      }
    }
  }
}

.btn {
  & {
    --bs-btn-font-size: 18px;
    --bs-btn-padding-x: 24px;
    --bs-btn-padding-y: 15.5px;
    --bs-btn-line-height: 100%;
    --bs-btn-border-width: 2px;
    --bs-border-radius: #{cssvar($global, border-radius)};

    &-sm {
      --bs-btn-padding-y: 8px;
      --bs-btn-padding-x: 14px;
      --bs-btn-font-size: 14px;
    }
  }

  align-items: center;
  display: inline-flex;
  @include font($fontM);

  &._hasIcon {
    --bs-btn-padding-y: 13.25px;
  }

  .i {
    font-size: calc(var(--bs-btn-font-size) + (var(--bs-btn-font-size) * 0.25));
  }

  & > span {
    display: inline-block;
    & + .i {
      margin-left: 7px;
    }
  }

  & > i {
    & + span {
      margin-left: 7px;
    }
  }

  &.rounded {
    --bs-border-radius: 10em;
  }

  @each $key, $clr in $color {
    @if (type-of($clr) != map) {
      @include button-variant("bs", $key, $color, app);
    }
  }
}

.text {
  @each $key, $clr in $color {
    @if (type-of($clr) != map) {
      &-#{$key} {
        color: $clr !important;
      }
    }
  }
}

// .fill {
//   @each $key, $clr in $color {
//     @if (type-of($clr) != map) {
//       &-#{$key} {
//         fill: $clr !important;
//       }
//     }
//   }
// }

// .stroke {
//   @each $key, $clr in $color {
//     @if (type-of($clr) != map) {
//       &-#{$key} {
//         stroke: $clr !important;
//       }
//     }
//   }
// }

// .o {
//   @for $i from 0 through 20 {
//     &-#{$i*5} {
//       opacity: ($i * 5) * 0.01;
//     }
//   }
// }

.t {
  &-l {
    text-align: left;
  }
  &-r {
    text-align: right;
  }
  &-c {
    text-align: center;
  }
  &-j {
    text-align: justify;
  }
}

.ib {
  vertical-align: middle;
  &,
  &-t,
  &-m,
  &-b,
  &-l {
    display: inline-block;
  }
  &-t {
    vertical-align: top;
  }
  &-m {
    vertical-align: middle;
  }
  &-b {
    vertical-align: bottom;
  }
  &-l {
    vertical-align: baseline;
  }
}

.m {
  &-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  &-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  &-l-auto {
    margin-left: auto !important;
  }
  &-r-auto {
    margin-right: auto !important;
  }
}

.w-auto {
  width: auto !important;
  &.min {
    min-width: initial;
  }
  &.max {
    max-width: initial;
  }
}

.h-auto {
  height: auto !important;
  &.min {
    min-height: initial;
  }
  &.max {
    max-height: initial;
  }
}

@each $tag in $tags {
  @each $side in $sides {
    @for $i from 0 through 6 {
      .#{nth($tag, 1)}-#{nth($side, 1)}-#{$i * $space} {
        @if nth($side, 1) == "x" or nth($side, 1) == "y" {
          #{nth($tag, 2)}#{nth($side, 2)}: #{$i * $space}px !important;
          #{nth($tag, 2)}#{nth($side, 3)}: #{$i * $space}px !important;
        } @else {
          #{nth($tag, 2)}#{nth($side, 2)}: #{$i * $space}px !important;
        }
      }
    }
  }
}

// /* Negative Margin */
@each $side in $sides {
  $tag: "margin";
  @for $i from 1 through 6 {
    .m-#{nth($side, 1)}--#{$i * $space} {
      @if nth($side, 1) == "x" or nth($side, 1) == "y" {
        #{$tag}#{nth($side, 2)}: #{$i * -$space}px !important;
        #{$tag}#{nth($side, 3)}: #{$i * -$space}px !important;
      } @else {
        #{$tag}#{nth($side, 2)}: #{$i * -$space}px !important;
      }
    }
  }
}

// Borders
[class*=" b-"],
[class^="b-"] {
  border-width: 0;
  border-style: solid;
  border-color: cssvar($theme, brdr);
  &.solid {
    border-style: solid;
  }
  &.dashed {
    border-style: dashed;
  }
  &.dotted {
    border-style: dotted;
  }
  &.double {
    border-style: double;
  }
}

@each $side in $sides {
  $tag: "border";
  @for $i from 0 through 5 {
    .b-#{nth($side, 1)}-#{$i} {
      @if nth($side, 1) == "x" or nth($side, 1) == "y" {
        #{$tag}#{nth($side, 2)}-width: #{$i}px !important;
        #{$tag}#{nth($side, 3)}-width: #{$i}px !important;
      } @else {
        #{$tag}#{nth($side, 2)}-width: #{$i}px !important;
      }
    }
  }
  @each $key, $clr in $color {
    @if (type-of($clr) != map) {
      .b-#{nth($side, 1)}-#{$key} {
        @if nth($side, 1) == "x" or nth($side, 1) == "y" {
          #{$tag}#{nth($side, 2)}-color: $clr !important;
          #{$tag}#{nth($side, 3)}-color: $clr !important;
        } @else {
          #{$tag}#{nth($side, 2)}-color: $clr !important;
        }
      }
    }
  }
}

// Width and Height
@for $i from 1 through 20 {
  .w-#{$i * 5} {
    width: percentage($i * 5 * 0.01);
  }

  .h-#{$i * 5} {
    height: percentage($i * 5 * 0.01);
  }

  @if $i <=16 {
    .w-#{$i * 20}px {
      width: #{$i * 20}px !important;
      &.min,
      &.max {
        width: auto !important;
      }
      // &.min {
      //   min-width: #{$i * 20}px !important;
      //   overflow: visible;
      // }
      // &.max {
      //   max-width: #{$i * 20}px !important;
      // }
      // &.scroll {
      //   overflow: auto;
      //   &-x {
      //     overflow-y: hidden;
      //   }
      //   &-y {
      //     overflow-x: hidden;
      //   }
      // }
    }
  }

  $h: $i * 40;
  .h-#{$h}px {
    overflow: hidden;
    height: #{$h}px !important;
    &.min,
    &.max {
      height: auto !important;
    }
    // &.min {
    //   min-height: #{$h}px !important;
    //   overflow: visible;
    // }
    // &.max {
    //   max-height: #{$h}px !important;
    // }
    // &.scroll {
    //   overflow: auto;
    //   &-x {
    //     overflow-y: hidden;
    //   }
    //   &-y {
    //     overflow-x: hidden;
    //   }
    // }
  }
}

.i {
  display: inline-block;
  &-stack {
    width: 2em;
    height: 2em;
    line-height: 2em;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &-1x,
    &-2x {
      left: 0;
      width: 100%;
      position: absolute;
      text-align: center;
    }
    &-1x {
      line-height: inherit;
    }
    &-2x {
      font-size: 2em;
    }
  }
  &-0_3x {
    font-size: 0.3em;
  }
  &-0_5x {
    font-size: 0.5em;
  }
  &-0_7x {
    font-size: 0.7em;
  }
  &-1_3x {
    font-size: 1.3em;
  }
  &-1_5x {
    font-size: 1.5em;
  }
  &-1_7x {
    font-size: 1.7em;
  }
  &-2x {
    font-size: 2em;
  }
  &-2_3x {
    font-size: 2.3em;
  }
  &-2_5x {
    font-size: 2.5em;
  }
  &-2_7x {
    font-size: 2.7em;
  }
  &-3x {
    font-size: 3em;
  }
  &-3_3x {
    font-size: 3.3em;
  }
  &-3_5x {
    font-size: 3.5em;
  }
  &-3_7x {
    font-size: 3.7em;
  }
  &-4x {
    font-size: 4em;
  }
  &-4_3x {
    font-size: 4.3em;
  }
  &-4_5x {
    font-size: 4.5em;
  }
  &-4_7x {
    font-size: 4.7em;
  }
  &-5x {
    font-size: 5em;
  }
  &-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    @include prefix(
      (
        transform: rotate(90deg)
      ),
      webkit ms
    );
  }
  &-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    @include prefix(
      (
        transform: rotate(180deg)
      ),
      webkit ms
    );
  }
  &-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    @include prefix(
      (
        transform: rotate(270deg)
      ),
      webkit ms
    );
  }
  &-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
    @include prefix(
      (
        transform: scale(-1, 1)
      ),
      webkit ms
    );
  }
  &-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    @include prefix(
      (
        transform: scale(1, -1)
      ),
      webkit ms
    );
  }
}

:root {
  .i-rotate-90,
  .i-rotate-180,
  .i-rotate-270,
  .i-flip-vertical,
  .i-flip-horizontal {
    filter: none;
  }
}
