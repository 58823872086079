body {
  &.onLoading {

    &:after,
    &:before {
      content: "";
      position: fixed;
    }

    &:before {
      z-index: 9999;
      pointer-events: all;
      background-color: rgba(#ccc, 0.8) !important;
      @extend .absFC;
    }

    &:after {
      top: 50%;
      left: 50%;
      z-index: 10000;
      @extend .loader;
      border-color: rgba(#000, 0.3);
      border-top-color: cssvar($theme, loader);
      margin: remc(60px * 0.5) 0 0 remc(-60px * 0.5);
    }

    &[data-loader-message]:after {
      left: 0;
      right: 0;
      top: 50%;
      z-index: 10001;
      margin: 0 auto;
      display: block;
      position: absolute;
      text-align: center;
      background: #fff;
      margin-top: remc(25px);
      color: cssvar($theme, color);
      content: attr(data-loader-message);
    }
  }
}

// main {
// }

.page {
  // padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 100vh;
  position: absolute;
  min-height: remc(1920px);
  background: cssvar($theme, bg);
  // height: 100vh;
  // overflow: scroll; // /* has to be scroll, not auto
  // overflow-x: hidden;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  // @extend %animateFast;
}

.p-paginator-page {
  .p-ink {
    height: 0px !important;
    width: 0px !important;
  }
}

// for dialog 

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, .4);
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, .4);
  transition-duration: .2s;
}

.p-dialog .p-dialog-header {
  height: 1px;
  color: #ffffff;
  background-color: #0b109f;
  padding: 30px;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #fff;
  color: #495057;
  padding: 1.5rem;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.p-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.p-dialog {
  min-width: 500px;
  background: white;

  .p-dialog-header {
    background: #0b109f;

    span {
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .p-dialog-header-icon {
      background: transparent;
      color: white;
      border: none;
      font-weight: bolder;
      font-size: 25px;
    }
  }

  .p-dialog-content {
    display: flex;
    align-items: center;
    padding: 10px 24px 25px 24px;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .p-dialog-footer {
    flex-shrink: 0;
    padding: 10px 24px;
    text-align: right;

    button {
      background: #1d9add;
      color: white;
      border: none;
      padding: 5px 15px;
      font-size: 15px;
      font-weight: 700;
      margin-right: 15px;

      .p-icon-wrapper {
        display: none;
      }
    }
  }
}

.p-toast-message {
  background: green;
  padding: 20px 30px;

  &.p-toast-message-error {
    background: red;
    padding: 20px 30px;
  }

  .p-toast-message-content {
    align-items: center;

    .p-toast-message-text {

      .p-toast-summary {
        color: white;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .p-toast-icon-close.p-link {
    cursor: pointer;
    background: none;
    border: none;
    color: white;
  }

  /* Hide the icon in PrimeNG toast */
  .p-toast-message-icon svg {
    display: none;
  }

  .p-dialog-footer,
  .p-confirm-dialog-reject svg {
    display: none;
  }

}

.infographic_dropdown,
.hospital_dropdown {
  .p-hidden-accessible {
    display: none;
  }

  .p-dropdown-items {
    background: white;
    box-shadow: 0px 0px 7px 0px grey;
    margin: 10px 3px 4px 3px;
    padding: 5px;
  }
}

.dropStyle {
  .p-dropdown-label {
    padding: 8px 0px 8px 8px;
  }
}

.p-dropdown {
  width: 100%;

  .p-placeholder {
    text-align: left;
  }

  .p-element {
    text-align: left;


    .p-dropdown-item {
      border-bottom: 0.5px solid #080575;

    }

    &:last-child {
      .p-dropdown-item {
        border-bottom: 0px solid #080575;
      }

    }

  }
}

.p-autocomplete {
  border: 1px solid #9f7b7b;
  box-shadow: none;
  outline: none;

  .input {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.medicineAutocomplete {

  ol,
  ul,
  dl,
  .p-autocomplete-multiple-container {
    margin-bottom: 0px !important;
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.hospital_dropdown {
  .p-dropdown {
    width: 100%;
    border-radius: 20px;


  }

  &.clr {
    .p-dropdown {
      border-color: #080575;
    }
  }



  .p-dropdown .p-placeholder {
    text-align: left;
    padding: 7px 10px;
    height: 100%;
  }

}

.hospital_dropdown-search {
  .p-dropdown {
    width: 100%;
    border-radius: 20px;


  }

  &.clr {
    .p-dropdown {
      border-color: #080575;
    }
  }

  .p-dropdown .p-placeholder {
    text-align: left;
    padding: 7px 10px;
    height: 100%;
  }

}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0rem 0rem;
}

.badgeBp {
  button {
    padding: 1px 1px 1px 1px;
    margin-left: 10px;
    font-size: 12px;
    background-color: red;
    border: none;
    color: black;

    span {
      font-weight: 300;
    }
  }
}