$icomoon-font-family: "icon" !default;
$icomoon-font-path: "fonts" !default;

$i-mamors_logo: unquote('"\\ee93"');
$i-play-path1: unquote('"\\ee99"');
$i-play-path2: unquote('"\\ee9a"');
$i-prescription-icon: unquote('"\\ee98"');
$i-file-prescription: unquote('"\\ee95"');
$i-vial: unquote('"\\ee96"');
$i-scale: unquote('"\\ee97"');
$i-eye: unquote('"\\ee94"');
$i-doctors: unquote('"\\ee9b"');
$i-pills: unquote('"\\ed7f"');
$i-ico-mamors: unquote('"\\ee90"');
$i-mamors: unquote('"\\ee91"');
$i-error: unquote('"\\e900"');
$i-error_outline: unquote('"\\e901"');
$i-warning: unquote('"\\e902"');
$i-add_alert: unquote('"\\e903"');
$i-notification_important: unquote('"\\e904"');
$i-album: unquote('"\\e905"');
$i-av_timer: unquote('"\\e906"');
$i-closed_caption: unquote('"\\e907"');
$i-equalizer: unquote('"\\e908"');
$i-explicit: unquote('"\\e909"');
$i-fast_forward: unquote('"\\e90a"');
$i-fast_rewind: unquote('"\\e90b"');
$i-games: unquote('"\\e90c"');
$i-hearing: unquote('"\\e90d"');
$i-high_quality: unquote('"\\e90e"');
$i-loop: unquote('"\\e90f"');
$i-mic: unquote('"\\e910"');
$i-mic_none: unquote('"\\e911"');
$i-mic_off: unquote('"\\e912"');
$i-movie: unquote('"\\e913"');
$i-library_add: unquote('"\\e914"');
$i-library_books: unquote('"\\e915"');
$i-library_music: unquote('"\\e916"');
$i-new_releases: unquote('"\\e917"');
$i-not_interested: unquote('"\\e918"');
$i-pause: unquote('"\\e919"');
$i-pause_circle_filled: unquote('"\\e91a"');
$i-pause_circle_outline: unquote('"\\e91b"');
$i-play_arrow: unquote('"\\e91c"');
$i-play_circle_filled: unquote('"\\e91d"');
$i-play_circle_outline: unquote('"\\e91e"');
$i-playlist_add: unquote('"\\e91f"');
$i-queue_music: unquote('"\\e920"');
$i-radio: unquote('"\\e921"');
$i-recent_actors: unquote('"\\e922"');
$i-repeat: unquote('"\\e923"');
$i-repeat_one: unquote('"\\e924"');
$i-replay: unquote('"\\e925"');
$i-shuffle: unquote('"\\e926"');
$i-skip_next: unquote('"\\e927"');
$i-skip_previous: unquote('"\\e928"');
$i-snooze: unquote('"\\e929"');
$i-stop: unquote('"\\e92a"');
$i-subtitles: unquote('"\\e92b"');
$i-surround_sound: unquote('"\\e92c"');
$i-video_library: unquote('"\\e92d"');
$i-videocam: unquote('"\\e92e"');
$i-videocam_off: unquote('"\\e92f"');
$i-volume_down: unquote('"\\e930"');
$i-volume_mute: unquote('"\\e931"');
$i-volume_off: unquote('"\\e932"');
$i-volume_up: unquote('"\\e933"');
$i-web: unquote('"\\e934"');
$i-hd: unquote('"\\e935"');
$i-sort_by_alpha: unquote('"\\e936"');
$i-airplay: unquote('"\\e937"');
$i-forward_10: unquote('"\\e938"');
$i-forward_30: unquote('"\\e939"');
$i-forward_5: unquote('"\\e93a"');
$i-replay_10: unquote('"\\e93b"');
$i-replay_30: unquote('"\\e93c"');
$i-replay_5: unquote('"\\e93d"');
$i-add_to_queue: unquote('"\\e93e"');
$i-fiber_dvr: unquote('"\\e93f"');
$i-fiber_new: unquote('"\\e940"');
$i-playlist_play: unquote('"\\e941"');
$i-art_track: unquote('"\\e942"');
$i-fiber_manual_record: unquote('"\\e943"');
$i-fiber_smart_record: unquote('"\\e944"');
$i-music_video: unquote('"\\e945"');
$i-subscriptions: unquote('"\\e946"');
$i-playlist_add_check: unquote('"\\e947"');
$i-queue_play_next: unquote('"\\e948"');
$i-remove_from_queue: unquote('"\\e949"');
$i-slow_motion_video: unquote('"\\e94a"');
$i-web_asset: unquote('"\\e94b"');
$i-fiber_pin: unquote('"\\e94c"');
$i-branding_watermark: unquote('"\\e94d"');
$i-call_to_action: unquote('"\\e94e"');
$i-featured_play_list: unquote('"\\e94f"');
$i-featured_video: unquote('"\\e950"');
$i-note: unquote('"\\e951"');
$i-video_call: unquote('"\\e952"');
$i-video_label: unquote('"\\e953"');
$i-4k: unquote('"\\e954"');
$i-missed_video_call: unquote('"\\e955"');
$i-control_camera: unquote('"\\e956"');
$i-business: unquote('"\\e957"');
$i-call: unquote('"\\e958"');
$i-call_end: unquote('"\\e959"');
$i-call_made: unquote('"\\e95a"');
$i-call_merge: unquote('"\\e95b"');
$i-call_missed: unquote('"\\e95c"');
$i-call_received: unquote('"\\e95d"');
$i-call_split: unquote('"\\e95e"');
$i-chat: unquote('"\\e95f"');
$i-clear_all: unquote('"\\e960"');
$i-comment: unquote('"\\e961"');
$i-contacts: unquote('"\\e962"');
$i-dialer_sip: unquote('"\\e963"');
$i-dialpad: unquote('"\\e964"');
$i-email: unquote('"\\e965"');
$i-forum: unquote('"\\e966"');
$i-import_export: unquote('"\\e967"');
$i-invert_colors_off: unquote('"\\e968"');
$i-live_help: unquote('"\\e969"');
$i-location_off: unquote('"\\e96a"');
$i-location_on: unquote('"\\e96b"');
$i-message: unquote('"\\e96c"');
$i-chat_bubble: unquote('"\\e96d"');
$i-chat_bubble_outline: unquote('"\\e96e"');
$i-no_sim: unquote('"\\e96f"');
$i-phone: unquote('"\\e970"');
$i-portable_wifi_off: unquote('"\\e971"');
$i-contact_phone: unquote('"\\e972"');
$i-contact_mail: unquote('"\\e973"');
$i-ring_volume: unquote('"\\e974"');
$i-speaker_phone: unquote('"\\e975"');
$i-stay_current_landscape: unquote('"\\e976"');
$i-stay_current_portrait: unquote('"\\e977"');
$i-swap_calls: unquote('"\\e978"');
$i-textsms: unquote('"\\e979"');
$i-voicemail: unquote('"\\e97a"');
$i-vpn_key: unquote('"\\e97b"');
$i-phonelink_erase: unquote('"\\e97c"');
$i-phonelink_lock: unquote('"\\e97d"');
$i-phonelink_ring: unquote('"\\e97e"');
$i-phonelink_setup: unquote('"\\e97f"');
$i-present_to_all: unquote('"\\e980"');
$i-import_contacts: unquote('"\\e981"');
$i-mail_outline: unquote('"\\e982"');
$i-screen_share: unquote('"\\e983"');
$i-stop_screen_share: unquote('"\\e984"');
$i-call_missed_outgoing: unquote('"\\e985"');
$i-rss_feed: unquote('"\\e986"');
$i-alternate_email: unquote('"\\e987"');
$i-mobile_screen_share: unquote('"\\e988"');
$i-add_call: unquote('"\\e989"');
$i-cancel_presentation: unquote('"\\e98a"');
$i-pause_presentation: unquote('"\\e98b"');
$i-unsubscribe: unquote('"\\e98c"');
$i-cell_wifi: unquote('"\\e98d"');
$i-sentiment_satisfied_alt: unquote('"\\e98e"');
$i-list_alt: unquote('"\\e98f"');
$i-domain_disabled: unquote('"\\e990"');
$i-lightbulb: unquote('"\\e991"');
$i-add: unquote('"\\e992"');
$i-add_box: unquote('"\\e993"');
$i-add_circle: unquote('"\\e994"');
$i-add_circle_outline: unquote('"\\e995"');
$i-archive: unquote('"\\e996"');
$i-backspace: unquote('"\\e997"');
$i-block: unquote('"\\e998"');
$i-clear: unquote('"\\e999"');
$i-content_copy: unquote('"\\e99a"');
$i-content_cut: unquote('"\\e99b"');
$i-content_paste: unquote('"\\e99c"');
$i-create: unquote('"\\e99d"');
$i-drafts: unquote('"\\e99e"');
$i-filter_list: unquote('"\\e99f"');
$i-flag: unquote('"\\e9a0"');
$i-forward: unquote('"\\e9a1"');
$i-gesture: unquote('"\\e9a2"');
$i-inbox: unquote('"\\e9a3"');
$i-link: unquote('"\\e9a4"');
$i-redo: unquote('"\\e9a5"');
$i-remove: unquote('"\\e9a6"');
$i-remove_circle: unquote('"\\e9a7"');
$i-remove_circle_outline: unquote('"\\e9a8"');
$i-reply: unquote('"\\e9a9"');
$i-reply_all: unquote('"\\e9aa"');
$i-report: unquote('"\\e9ab"');
$i-save: unquote('"\\e9ac"');
$i-select_all: unquote('"\\e9ad"');
$i-send: unquote('"\\e9ae"');
$i-sort: unquote('"\\e9af"');
$i-text_format: unquote('"\\e9b0"');
$i-undo: unquote('"\\e9b1"');
$i-font_download: unquote('"\\e9b2"');
$i-move_to_inbox: unquote('"\\e9b3"');
$i-unarchive: unquote('"\\e9b4"');
$i-next_week: unquote('"\\e9b5"');
$i-weekend: unquote('"\\e9b6"');
$i-delete_sweep: unquote('"\\e9b7"');
$i-low_priority: unquote('"\\e9b8"');
$i-outlined_flag: unquote('"\\e9b9"');
$i-link_off: unquote('"\\e9ba"');
$i-report_off: unquote('"\\e9bb"');
$i-save_alt: unquote('"\\e9bc"');
$i-ballot: unquote('"\\e9bd"');
$i-file_copy: unquote('"\\e9be"');
$i-how_to_reg: unquote('"\\e9bf"');
$i-how_to_vote: unquote('"\\e9c0"');
$i-waves: unquote('"\\e9c1"');
$i-where_to_vote: unquote('"\\e9c2"');
$i-add_link: unquote('"\\e9c3"');
$i-inventory: unquote('"\\e9c4"');
$i-access_alarm: unquote('"\\e9c5"');
$i-access_alarms: unquote('"\\e9c6"');
$i-access_time: unquote('"\\e9c7"');
$i-add_alarm: unquote('"\\e9c8"');
$i-airplanemode_off: unquote('"\\e9c9"');
$i-airplanemode_on: unquote('"\\e9ca"');
$i-battery_alert: unquote('"\\e9cb"');
$i-battery_charging_full: unquote('"\\e9cc"');
$i-battery_full: unquote('"\\e9cd"');
$i-battery_unknown: unquote('"\\e9ce"');
$i-bluetooth: unquote('"\\e9cf"');
$i-bluetooth_connected: unquote('"\\e9d0"');
$i-bluetooth_disabled: unquote('"\\e9d1"');
$i-bluetooth_searching: unquote('"\\e9d2"');
$i-brightness_auto: unquote('"\\e9d3"');
$i-brightness_high: unquote('"\\e9d4"');
$i-brightness_low: unquote('"\\e9d5"');
$i-brightness_medium: unquote('"\\e9d6"');
$i-data_usage: unquote('"\\e9d7"');
$i-developer_mode: unquote('"\\e9d8"');
$i-devices: unquote('"\\e9d9"');
$i-dvr: unquote('"\\e9da"');
$i-gps_fixed: unquote('"\\e9db"');
$i-gps_not_fixed: unquote('"\\e9dc"');
$i-gps_off: unquote('"\\e9dd"');
$i-graphic_eq: unquote('"\\e9de"');
$i-network_cell: unquote('"\\e9df"');
$i-network_wifi: unquote('"\\e9e0"');
$i-nfc: unquote('"\\e9e1"');
$i-now_wallpaper: unquote('"\\e9e2"');
$i-now_widgets: unquote('"\\e9e3"');
$i-screen_lock_landscape: unquote('"\\e9e4"');
$i-screen_lock_portrait: unquote('"\\e9e5"');
$i-screen_lock_rotation: unquote('"\\e9e6"');
$i-screen_rotation: unquote('"\\e9e7"');
$i-sd_storage: unquote('"\\e9e8"');
$i-settings_system_daydream: unquote('"\\e9e9"');
$i-signal_cellular_4_bar: unquote('"\\e9ea"');
$i-signal_cellular_connected_no_internet_4_bar: unquote('"\\e9eb"');
$i-signal_cellular_null: unquote('"\\e9ec"');
$i-signal_cellular_off: unquote('"\\e9ed"');
$i-signal_wifi_4_bar: unquote('"\\e9ee"');
$i-signal_wifi_4_bar_lock: unquote('"\\e9ef"');
$i-signal_wifi_off: unquote('"\\e9f0"');
$i-storage: unquote('"\\e9f1"');
$i-usb: unquote('"\\e9f2"');
$i-wifi_lock: unquote('"\\e9f3"');
$i-wifi_tethering: unquote('"\\e9f4"');
$i-add_to_home_screen: unquote('"\\e9f5"');
$i-device_thermostat: unquote('"\\e9f6"');
$i-mobile_friendly: unquote('"\\e9f7"');
$i-mobile_off: unquote('"\\e9f8"');
$i-signal_cellular_alt: unquote('"\\e9f9"');
$i-attach_file: unquote('"\\e9fa"');
$i-attach_money: unquote('"\\e9fb"');
$i-border_all: unquote('"\\e9fc"');
$i-border_bottom: unquote('"\\e9fd"');
$i-border_clear: unquote('"\\e9fe"');
$i-border_color: unquote('"\\e9ff"');
$i-border_horizontal: unquote('"\\ea00"');
$i-border_inner: unquote('"\\ea01"');
$i-border_left: unquote('"\\ea02"');
$i-border_outer: unquote('"\\ea03"');
$i-border_right: unquote('"\\ea04"');
$i-border_style: unquote('"\\ea05"');
$i-border_top: unquote('"\\ea06"');
$i-border_vertical: unquote('"\\ea07"');
$i-format_align_center: unquote('"\\ea08"');
$i-format_align_justify: unquote('"\\ea09"');
$i-format_align_left: unquote('"\\ea0a"');
$i-format_align_right: unquote('"\\ea0b"');
$i-format_bold: unquote('"\\ea0c"');
$i-format_clear: unquote('"\\ea0d"');
$i-format_color_fill: unquote('"\\ea0e"');
$i-format_color_reset: unquote('"\\ea0f"');
$i-format_color_text: unquote('"\\ea10"');
$i-format_indent_decrease: unquote('"\\ea11"');
$i-format_indent_increase: unquote('"\\ea12"');
$i-format_italic: unquote('"\\ea13"');
$i-format_line_spacing: unquote('"\\ea14"');
$i-format_list_bulleted: unquote('"\\ea15"');
$i-format_list_numbered: unquote('"\\ea16"');
$i-format_paint: unquote('"\\ea17"');
$i-format_quote: unquote('"\\ea18"');
$i-format_size: unquote('"\\ea19"');
$i-format_strikethrough: unquote('"\\ea1a"');
$i-format_textdirection_l_to_r: unquote('"\\ea1b"');
$i-format_textdirection_r_to_l: unquote('"\\ea1c"');
$i-format_underlined: unquote('"\\ea1d"');
$i-functions: unquote('"\\ea1e"');
$i-insert_chart: unquote('"\\ea1f"');
$i-insert_comment: unquote('"\\ea20"');
$i-insert_drive_file: unquote('"\\ea21"');
$i-insert_emoticon: unquote('"\\ea22"');
$i-insert_invitation: unquote('"\\ea23"');
$i-insert_photo: unquote('"\\ea24"');
$i-mode_comment: unquote('"\\ea25"');
$i-publish: unquote('"\\ea26"');
$i-space_bar: unquote('"\\ea27"');
$i-strikethrough_s: unquote('"\\ea28"');
$i-vertical_align_bottom: unquote('"\\ea29"');
$i-vertical_align_center: unquote('"\\ea2a"');
$i-vertical_align_top: unquote('"\\ea2b"');
$i-wrap_text: unquote('"\\ea2c"');
$i-money_off: unquote('"\\ea2d"');
$i-drag_handle: unquote('"\\ea2e"');
$i-format_shapes: unquote('"\\ea2f"');
$i-highlight: unquote('"\\ea30"');
$i-linear_scale: unquote('"\\ea31"');
$i-short_text: unquote('"\\ea32"');
$i-text_fields: unquote('"\\ea33"');
$i-monetization_on: unquote('"\\ea34"');
$i-title: unquote('"\\ea35"');
$i-table_chart: unquote('"\\ea36"');
$i-add_comment: unquote('"\\ea37"');
$i-format_list_numbered_rtl: unquote('"\\ea38"');
$i-scatter_plot: unquote('"\\ea39"');
$i-score: unquote('"\\ea3a"');
$i-insert_chart_outlined: unquote('"\\ea3b"');
$i-bar_chart: unquote('"\\ea3c"');
$i-notes: unquote('"\\ea3d"');
$i-attachment: unquote('"\\ea3e"');
$i-cloud: unquote('"\\ea3f"');
$i-cloud_circle: unquote('"\\ea40"');
$i-cloud_done: unquote('"\\ea41"');
$i-cloud_download: unquote('"\\ea42"');
$i-cloud_off: unquote('"\\ea43"');
$i-cloud_queue: unquote('"\\ea44"');
$i-cloud_upload: unquote('"\\ea45"');
$i-file_download: unquote('"\\ea46"');
$i-file_upload: unquote('"\\ea47"');
$i-folder: unquote('"\\ea48"');
$i-folder_open: unquote('"\\ea49"');
$i-folder_shared: unquote('"\\ea4a"');
$i-create_new_folder: unquote('"\\ea4b"');
$i-cast: unquote('"\\ea4c"');
$i-cast_connected: unquote('"\\ea4d"');
$i-computer: unquote('"\\ea4e"');
$i-desktop_mac: unquote('"\\ea4f"');
$i-desktop_windows: unquote('"\\ea50"');
$i-developer_board: unquote('"\\ea51"');
$i-dock: unquote('"\\ea52"');
$i-headset: unquote('"\\ea53"');
$i-headset_mic: unquote('"\\ea54"');
$i-keyboard: unquote('"\\ea55"');
$i-keyboard_arrow_down: unquote('"\\ea56"');
$i-keyboard_arrow_left: unquote('"\\ea57"');
$i-keyboard_arrow_right: unquote('"\\ea58"');
$i-keyboard_arrow_up: unquote('"\\ea59"');
$i-keyboard_backspace: unquote('"\\ea5a"');
$i-keyboard_capslock: unquote('"\\ea5b"');
$i-keyboard_hide: unquote('"\\ea5c"');
$i-keyboard_return: unquote('"\\ea5d"');
$i-keyboard_tab: unquote('"\\ea5e"');
$i-keyboard_voice: unquote('"\\ea5f"');
$i-laptop_chromebook: unquote('"\\ea60"');
$i-laptop_mac: unquote('"\\ea61"');
$i-laptop_windows: unquote('"\\ea62"');
$i-memory: unquote('"\\ea63"');
$i-mouse: unquote('"\\ea64"');
$i-phone_android: unquote('"\\ea65"');
$i-phone_iphone: unquote('"\\ea66"');
$i-phonelink_off: unquote('"\\ea67"');
$i-router: unquote('"\\ea68"');
$i-scanner: unquote('"\\ea69"');
$i-security: unquote('"\\ea6a"');
$i-sim_card: unquote('"\\ea6b"');
$i-speaker: unquote('"\\ea6c"');
$i-speaker_group: unquote('"\\ea6d"');
$i-tablet: unquote('"\\ea6e"');
$i-tablet_android: unquote('"\\ea6f"');
$i-tablet_mac: unquote('"\\ea70"');
$i-toys: unquote('"\\ea71"');
$i-tv: unquote('"\\ea72"');
$i-watch: unquote('"\\ea73"');
$i-device_hub: unquote('"\\ea74"');
$i-power_input: unquote('"\\ea75"');
$i-devices_other: unquote('"\\ea76"');
$i-videogame_asset: unquote('"\\ea77"');
$i-device_unknown: unquote('"\\ea78"');
$i-headset_off: unquote('"\\ea79"');
$i-adjust: unquote('"\\ea7a"');
$i-assistant: unquote('"\\ea7b"');
$i-audiotrack: unquote('"\\ea7c"');
$i-blur_circular: unquote('"\\ea7d"');
$i-blur_linear: unquote('"\\ea7e"');
$i-blur_off: unquote('"\\ea7f"');
$i-blur_on: unquote('"\\ea80"');
$i-brightness_1: unquote('"\\ea81"');
$i-brightness_2: unquote('"\\ea82"');
$i-brightness_3: unquote('"\\ea83"');
$i-brightness_4: unquote('"\\ea84"');
$i-broken_image: unquote('"\\ea85"');
$i-brush: unquote('"\\ea86"');
$i-camera: unquote('"\\ea87"');
$i-camera_alt: unquote('"\\ea88"');
$i-camera_front: unquote('"\\ea89"');
$i-camera_rear: unquote('"\\ea8a"');
$i-camera_roll: unquote('"\\ea8b"');
$i-center_focus_strong: unquote('"\\ea8c"');
$i-center_focus_weak: unquote('"\\ea8d"');
$i-collections: unquote('"\\ea8e"');
$i-color_lens: unquote('"\\ea8f"');
$i-colorize: unquote('"\\ea90"');
$i-compare: unquote('"\\ea91"');
$i-control_point_duplicate: unquote('"\\ea92"');
$i-crop_16_9: unquote('"\\ea93"');
$i-crop_3_2: unquote('"\\ea94"');
$i-crop: unquote('"\\ea95"');
$i-crop_5_4: unquote('"\\ea96"');
$i-crop_7_5: unquote('"\\ea97"');
$i-crop_din: unquote('"\\ea98"');
$i-crop_free: unquote('"\\ea99"');
$i-crop_original: unquote('"\\ea9a"');
$i-crop_portrait: unquote('"\\ea9b"');
$i-crop_square: unquote('"\\ea9c"');
$i-dehaze: unquote('"\\ea9d"');
$i-details: unquote('"\\ea9e"');
$i-exposure: unquote('"\\ea9f"');
$i-exposure_neg_1: unquote('"\\eaa0"');
$i-exposure_neg_2: unquote('"\\eaa1"');
$i-exposure_plus_1: unquote('"\\eaa2"');
$i-exposure_plus_2: unquote('"\\eaa3"');
$i-exposure_zero: unquote('"\\eaa4"');
$i-filter_1: unquote('"\\eaa5"');
$i-filter_2: unquote('"\\eaa6"');
$i-filter_3: unquote('"\\eaa7"');
$i-filter: unquote('"\\eaa8"');
$i-filter_4: unquote('"\\eaa9"');
$i-filter_5: unquote('"\\eaaa"');
$i-filter_6: unquote('"\\eaab"');
$i-filter_7: unquote('"\\eaac"');
$i-filter_8: unquote('"\\eaad"');
$i-filter_9: unquote('"\\eaae"');
$i-filter_9_plus: unquote('"\\eaaf"');
$i-filter_b_and_w: unquote('"\\eab0"');
$i-filter_center_focus: unquote('"\\eab1"');
$i-filter_drama: unquote('"\\eab2"');
$i-filter_frames: unquote('"\\eab3"');
$i-filter_hdr: unquote('"\\eab4"');
$i-filter_none: unquote('"\\eab5"');
$i-filter_tilt_shift: unquote('"\\eab6"');
$i-filter_vintage: unquote('"\\eab7"');
$i-flare: unquote('"\\eab8"');
$i-flash_auto: unquote('"\\eab9"');
$i-flash_off: unquote('"\\eaba"');
$i-flash_on: unquote('"\\eabb"');
$i-flip: unquote('"\\eabc"');
$i-gradient: unquote('"\\eabd"');
$i-grain: unquote('"\\eabe"');
$i-grid_off: unquote('"\\eabf"');
$i-grid_on: unquote('"\\eac0"');
$i-hdr_off: unquote('"\\eac1"');
$i-hdr_on: unquote('"\\eac2"');
$i-hdr_strong: unquote('"\\eac3"');
$i-hdr_weak: unquote('"\\eac4"');
$i-healing: unquote('"\\eac5"');
$i-image_aspect_ratio: unquote('"\\eac6"');
$i-iso: unquote('"\\eac7"');
$i-leak_add: unquote('"\\eac8"');
$i-leak_remove: unquote('"\\eac9"');
$i-lens: unquote('"\\eaca"');
$i-looks_3: unquote('"\\eacb"');
$i-looks: unquote('"\\eacc"');
$i-looks_4: unquote('"\\eacd"');
$i-looks_5: unquote('"\\eace"');
$i-looks_6: unquote('"\\eacf"');
$i-looks_one: unquote('"\\ead0"');
$i-looks_two: unquote('"\\ead1"');
$i-loupe: unquote('"\\ead2"');
$i-monochrome_photos: unquote('"\\ead3"');
$i-music_note: unquote('"\\ead4"');
$i-nature: unquote('"\\ead5"');
$i-nature_people: unquote('"\\ead6"');
$i-navigate_before: unquote('"\\ead7"');
$i-navigate_next: unquote('"\\ead8"');
$i-panorama: unquote('"\\ead9"');
$i-radio_button_unchecked: unquote('"\\eada"');
$i-panorama_horizontal: unquote('"\\eadb"');
$i-panorama_vertical: unquote('"\\eadc"');
$i-panorama_wide_angle: unquote('"\\eadd"');
$i-photo_album: unquote('"\\eade"');
$i-picture_as_pdf: unquote('"\\eadf"');
$i-portrait: unquote('"\\eae0"');
$i-remove_red_eye: unquote('"\\eae1"');
$i-rotate_90_degrees_ccw: unquote('"\\eae2"');
$i-rotate_left: unquote('"\\eae3"');
$i-rotate_right: unquote('"\\eae4"');
$i-slideshow: unquote('"\\eae5"');
$i-straighten: unquote('"\\eae6"');
$i-style: unquote('"\\eae7"');
$i-switch_camera: unquote('"\\eae8"');
$i-switch_video: unquote('"\\eae9"');
$i-texture: unquote('"\\eaea"');
$i-timelapse: unquote('"\\eaeb"');
$i-timer_10: unquote('"\\eaec"');
$i-timer_3: unquote('"\\eaed"');
$i-timer: unquote('"\\eaee"');
$i-timer_off: unquote('"\\eaef"');
$i-tonality: unquote('"\\eaf0"');
$i-transform: unquote('"\\eaf1"');
$i-tune: unquote('"\\eaf2"');
$i-view_comfy: unquote('"\\eaf3"');
$i-view_compact: unquote('"\\eaf4"');
$i-wb_auto: unquote('"\\eaf5"');
$i-wb_cloudy: unquote('"\\eaf6"');
$i-wb_incandescent: unquote('"\\eaf7"');
$i-wb_sunny: unquote('"\\eaf8"');
$i-collections_bookmark: unquote('"\\eaf9"');
$i-photo_size_select_actual: unquote('"\\eafa"');
$i-photo_size_select_large: unquote('"\\eafb"');
$i-photo_size_select_small: unquote('"\\eafc"');
$i-vignette: unquote('"\\eafd"');
$i-wb_iridescent: unquote('"\\eafe"');
$i-crop_rotate: unquote('"\\eaff"');
$i-linked_camera: unquote('"\\eb00"');
$i-add_a_photo: unquote('"\\eb01"');
$i-movie_filter: unquote('"\\eb02"');
$i-photo_filter: unquote('"\\eb03"');
$i-burst_mode: unquote('"\\eb04"');
$i-shutter_speed: unquote('"\\eb05"');
$i-add_photo_alternate: unquote('"\\eb06"');
$i-image_search: unquote('"\\eb07"');
$i-music_off: unquote('"\\eb08"');
$i-beenhere: unquote('"\\eb09"');
$i-directions: unquote('"\\eb0a"');
$i-directions_bike: unquote('"\\eb0b"');
$i-directions_bus: unquote('"\\eb0c"');
$i-directions_car: unquote('"\\eb0d"');
$i-directions_ferry: unquote('"\\eb0e"');
$i-directions_subway: unquote('"\\eb0f"');
$i-directions_train: unquote('"\\eb10"');
$i-directions_walk: unquote('"\\eb11"');
$i-hotel: unquote('"\\eb12"');
$i-layers: unquote('"\\eb13"');
$i-layers_clear: unquote('"\\eb14"');
$i-local_atm: unquote('"\\eb15"');
$i-local_attraction: unquote('"\\eb16"');
$i-local_bar: unquote('"\\eb17"');
$i-local_cafe: unquote('"\\eb18"');
$i-local_car_wash: unquote('"\\eb19"');
$i-local_convenience_store: unquote('"\\eb1a"');
$i-local_drink: unquote('"\\eb1b"');
$i-local_florist: unquote('"\\eb1c"');
$i-local_gas_station: unquote('"\\eb1d"');
$i-local_grocery_store: unquote('"\\eb1e"');
$i-local_hospital: unquote('"\\eb1f"');
$i-local_laundry_service: unquote('"\\eb20"');
$i-local_library: unquote('"\\eb21"');
$i-local_mall: unquote('"\\eb22"');
$i-local_movies: unquote('"\\eb23"');
$i-local_offer: unquote('"\\eb24"');
$i-local_parking: unquote('"\\eb25"');
$i-local_pharmacy: unquote('"\\eb26"');
$i-local_pizza: unquote('"\\eb27"');
$i-local_printshop: unquote('"\\eb28"');
$i-local_restaurant: unquote('"\\eb29"');
$i-local_shipping: unquote('"\\eb2a"');
$i-local_taxi: unquote('"\\eb2b"');
$i-location_history: unquote('"\\eb2c"');
$i-map: unquote('"\\eb2d"');
$i-navigation: unquote('"\\eb2e"');
$i-pin_drop: unquote('"\\eb2f"');
$i-rate_review: unquote('"\\eb30"');
$i-satellite: unquote('"\\eb31"');
$i-store_mall_directory: unquote('"\\eb32"');
$i-traffic: unquote('"\\eb33"');
$i-directions_run: unquote('"\\eb34"');
$i-add_location: unquote('"\\eb35"');
$i-edit_location: unquote('"\\eb36"');
$i-near_me: unquote('"\\eb37"');
$i-person_pin_circle: unquote('"\\eb38"');
$i-zoom_out_map: unquote('"\\eb39"');
$i-restaurant: unquote('"\\eb3a"');
$i-ev_station: unquote('"\\eb3b"');
$i-streetview: unquote('"\\eb3c"');
$i-subway: unquote('"\\eb3d"');
$i-train: unquote('"\\eb3e"');
$i-tram: unquote('"\\eb3f"');
$i-transfer_within_a_station: unquote('"\\eb40"');
$i-atm: unquote('"\\eb41"');
$i-category: unquote('"\\eb42"');
$i-not_listed_location: unquote('"\\eb43"');
$i-departure_board: unquote('"\\eb44"');
$i-360: unquote('"\\eb45"');
$i-edit_attributes: unquote('"\\eb46"');
$i-transit_enterexit: unquote('"\\eb47"');
$i-fastfood: unquote('"\\eb48"');
$i-trip_origin: unquote('"\\eb49"');
$i-compass_calibration: unquote('"\\eb4a"');
$i-money: unquote('"\\eb4b"');
$i-apps: unquote('"\\eb4c"');
$i-arrow_back: unquote('"\\eb4d"');
$i-arrow_drop_down: unquote('"\\eb4e"');
$i-arrow_drop_down_circle: unquote('"\\eb4f"');
$i-arrow_drop_up: unquote('"\\eb50"');
$i-arrow_forward: unquote('"\\eb51"');
$i-cancel: unquote('"\\eb52"');
$i-check: unquote('"\\eb53"');
$i-expand_less: unquote('"\\eb54"');
$i-expand_more: unquote('"\\eb55"');
$i-fullscreen: unquote('"\\eb56"');
$i-fullscreen_exit: unquote('"\\eb57"');
$i-menu: unquote('"\\eb58"');
$i-keyboard_control: unquote('"\\eb59"');
$i-more_vert: unquote('"\\eb5a"');
$i-refresh: unquote('"\\eb5b"');
$i-unfold_less: unquote('"\\eb5c"');
$i-unfold_more: unquote('"\\eb5d"');
$i-arrow_upward: unquote('"\\eb5e"');
$i-subdirectory_arrow_left: unquote('"\\eb5f"');
$i-subdirectory_arrow_right: unquote('"\\eb60"');
$i-arrow_downward: unquote('"\\eb61"');
$i-first_page: unquote('"\\eb62"');
$i-last_page: unquote('"\\eb63"');
$i-arrow_left: unquote('"\\eb64"');
$i-arrow_right: unquote('"\\eb65"');
$i-arrow_back_ios: unquote('"\\eb66"');
$i-arrow_forward_ios: unquote('"\\eb67"');
$i-adb: unquote('"\\eb68"');
$i-disc_full: unquote('"\\eb69"');
$i-do_not_disturb_alt: unquote('"\\eb6a"');
$i-drive_eta: unquote('"\\eb6b"');
$i-event_available: unquote('"\\eb6c"');
$i-event_busy: unquote('"\\eb6d"');
$i-event_note: unquote('"\\eb6e"');
$i-folder_special: unquote('"\\eb6f"');
$i-mms: unquote('"\\eb70"');
$i-more: unquote('"\\eb71"');
$i-network_locked: unquote('"\\eb72"');
$i-phone_bluetooth_speaker: unquote('"\\eb73"');
$i-phone_forwarded: unquote('"\\eb74"');
$i-phone_in_talk: unquote('"\\eb75"');
$i-phone_locked: unquote('"\\eb76"');
$i-phone_missed: unquote('"\\eb77"');
$i-phone_paused: unquote('"\\eb78"');
$i-sim_card_alert: unquote('"\\eb79"');
$i-sms_failed: unquote('"\\eb7a"');
$i-sync_disabled: unquote('"\\eb7b"');
$i-sync_problem: unquote('"\\eb7c"');
$i-system_update: unquote('"\\eb7d"');
$i-tap_and_play: unquote('"\\eb7e"');
$i-vibration: unquote('"\\eb7f"');
$i-voice_chat: unquote('"\\eb80"');
$i-vpn_lock: unquote('"\\eb81"');
$i-airline_seat_flat: unquote('"\\eb82"');
$i-airline_seat_flat_angled: unquote('"\\eb83"');
$i-airline_seat_individual_suite: unquote('"\\eb84"');
$i-airline_seat_legroom_extra: unquote('"\\eb85"');
$i-airline_seat_legroom_normal: unquote('"\\eb86"');
$i-airline_seat_legroom_reduced: unquote('"\\eb87"');
$i-airline_seat_recline_extra: unquote('"\\eb88"');
$i-airline_seat_recline_normal: unquote('"\\eb89"');
$i-confirmation_number: unquote('"\\eb8a"');
$i-live_tv: unquote('"\\eb8b"');
$i-ondemand_video: unquote('"\\eb8c"');
$i-personal_video: unquote('"\\eb8d"');
$i-power: unquote('"\\eb8e"');
$i-wc: unquote('"\\eb8f"');
$i-wifi: unquote('"\\eb90"');
$i-enhanced_encryption: unquote('"\\eb91"');
$i-network_check: unquote('"\\eb92"');
$i-no_encryption: unquote('"\\eb93"');
$i-rv_hookup: unquote('"\\eb94"');
$i-do_not_disturb_off: unquote('"\\eb95"');
$i-priority_high: unquote('"\\eb96"');
$i-power_off: unquote('"\\eb97"');
$i-tv_off: unquote('"\\eb98"');
$i-wifi_off: unquote('"\\eb99"');
$i-phone_callback: unquote('"\\eb9a"');
$i-pie_chart: unquote('"\\eb9b"');
$i-pie_chart_outlined: unquote('"\\eb9c"');
$i-bubble_chart: unquote('"\\eb9d"');
$i-multiline_chart: unquote('"\\eb9e"');
$i-show_chart: unquote('"\\eb9f"');
$i-cake: unquote('"\\eba0"');
$i-group: unquote('"\\eba1"');
$i-group_add: unquote('"\\eba2"');
$i-location_city: unquote('"\\eba3"');
$i-mood_bad: unquote('"\\eba4"');
$i-notifications: unquote('"\\eba5"');
$i-notifications_none: unquote('"\\eba6"');
$i-notifications_off: unquote('"\\eba7"');
$i-notifications_on: unquote('"\\eba8"');
$i-notifications_paused: unquote('"\\eba9"');
$i-pages: unquote('"\\ebaa"');
$i-party_mode: unquote('"\\ebab"');
$i-people_outline: unquote('"\\ebac"');
$i-person: unquote('"\\ebad"');
$i-person_add: unquote('"\\ebae"');
$i-person_outline: unquote('"\\ebaf"');
$i-plus_one: unquote('"\\ebb0"');
$i-public: unquote('"\\ebb1"');
$i-school: unquote('"\\ebb2"');
$i-share: unquote('"\\ebb3"');
$i-whatshot: unquote('"\\ebb4"');
$i-sentiment_dissatisfied: unquote('"\\ebb5"');
$i-sentiment_neutral: unquote('"\\ebb6"');
$i-sentiment_satisfied: unquote('"\\ebb7"');
$i-sentiment_very_dissatisfied: unquote('"\\ebb8"');
$i-sentiment_very_satisfied: unquote('"\\ebb9"');
$i-thumb_down_alt: unquote('"\\ebba"');
$i-thumb_up_alt: unquote('"\\ebbb"');
$i-check_box: unquote('"\\ebbc"');
$i-check_box_outline_blank: unquote('"\\ebbd"');
$i-radio_button_on: unquote('"\\ebbe"');
$i-star: unquote('"\\ebbf"');
$i-star_half: unquote('"\\ebc0"');
$i-star_outline: unquote('"\\ebc1"');
$i-3d_rotation: unquote('"\\ebc2"');
$i-accessibility: unquote('"\\ebc3"');
$i-account_balance: unquote('"\\ebc4"');
$i-account_balance_wallet: unquote('"\\ebc5"');
$i-account_box: unquote('"\\ebc6"');
$i-account_circle: unquote('"\\ebc7"');
$i-add_shopping_cart: unquote('"\\ebc8"');
$i-alarm_off: unquote('"\\ebc9"');
$i-alarm_on: unquote('"\\ebca"');
$i-android: unquote('"\\ebcb"');
$i-announcement: unquote('"\\ebcc"');
$i-aspect_ratio: unquote('"\\ebcd"');
$i-assignment: unquote('"\\ebce"');
$i-assignment_ind: unquote('"\\ebcf"');
$i-assignment_late: unquote('"\\ebd0"');
$i-assignment_return: unquote('"\\ebd1"');
$i-assignment_returned: unquote('"\\ebd2"');
$i-assignment_turned_in: unquote('"\\ebd3"');
$i-autorenew: unquote('"\\ebd4"');
$i-book: unquote('"\\ebd5"');
$i-bookmark: unquote('"\\ebd6"');
$i-bookmark_outline: unquote('"\\ebd7"');
$i-bug_report: unquote('"\\ebd8"');
$i-build: unquote('"\\ebd9"');
$i-cached: unquote('"\\ebda"');
$i-change_history: unquote('"\\ebdb"');
$i-check_circle: unquote('"\\ebdc"');
$i-chrome_reader_mode: unquote('"\\ebdd"');
$i-code: unquote('"\\ebde"');
$i-credit_card: unquote('"\\ebdf"');
$i-dashboard: unquote('"\\ebe0"');
$i-delete: unquote('"\\ebe1"');
$i-description: unquote('"\\ebe2"');
$i-dns: unquote('"\\ebe3"');
$i-done: unquote('"\\ebe4"');
$i-done_all: unquote('"\\ebe5"');
$i-exit_to_app: unquote('"\\ebe6"');
$i-explore: unquote('"\\ebe7"');
$i-extension: unquote('"\\ebe8"');
$i-face: unquote('"\\ebe9"');
$i-favorite: unquote('"\\ebea"');
$i-favorite_outline: unquote('"\\ebeb"');
$i-find_in_page: unquote('"\\ebec"');
$i-find_replace: unquote('"\\ebed"');
$i-flip_to_back: unquote('"\\ebee"');
$i-flip_to_front: unquote('"\\ebef"');
$i-group_work: unquote('"\\ebf0"');
$i-help: unquote('"\\ebf1"');
$i-highlight_remove: unquote('"\\ebf2"');
$i-history: unquote('"\\ebf3"');
$i-home: unquote('"\\ebf4"');
$i-hourglass_empty: unquote('"\\ebf5"');
$i-hourglass_full: unquote('"\\ebf6"');
$i-https: unquote('"\\ebf7"');
$i-info: unquote('"\\ebf8"');
$i-info_outline: unquote('"\\ebf9"');
$i-input: unquote('"\\ebfa"');
$i-invert_colors_on: unquote('"\\ebfb"');
$i-label: unquote('"\\ebfc"');
$i-label_outline: unquote('"\\ebfd"');
$i-language: unquote('"\\ebfe"');
$i-launch: unquote('"\\ebff"');
$i-list: unquote('"\\ec00"');
$i-lock_open: unquote('"\\ec01"');
$i-lock_outline: unquote('"\\ec02"');
$i-loyalty: unquote('"\\ec03"');
$i-markunread_mailbox: unquote('"\\ec04"');
$i-note_add: unquote('"\\ec05"');
$i-open_in_browser: unquote('"\\ec06"');
$i-open_with: unquote('"\\ec07"');
$i-pageview: unquote('"\\ec08"');
$i-perm_camera_mic: unquote('"\\ec09"');
$i-perm_contact_calendar: unquote('"\\ec0a"');
$i-perm_data_setting: unquote('"\\ec0b"');
$i-perm_device_information: unquote('"\\ec0c"');
$i-perm_media: unquote('"\\ec0d"');
$i-perm_phone_msg: unquote('"\\ec0e"');
$i-perm_scan_wifi: unquote('"\\ec0f"');
$i-picture_in_picture: unquote('"\\ec10"');
$i-polymer: unquote('"\\ec11"');
$i-power_settings_new: unquote('"\\ec12"');
$i-receipt: unquote('"\\ec13"');
$i-redeem: unquote('"\\ec14"');
$i-search: unquote('"\\ec15"');
$i-settings: unquote('"\\ec16"');
$i-settings_applications: unquote('"\\ec17"');
$i-settings_backup_restore: unquote('"\\ec18"');
$i-settings_bluetooth: unquote('"\\ec19"');
$i-settings_cell: unquote('"\\ec1a"');
$i-settings_display: unquote('"\\ec1b"');
$i-settings_ethernet: unquote('"\\ec1c"');
$i-settings_input_antenna: unquote('"\\ec1d"');
$i-settings_input_component: unquote('"\\ec1e"');
$i-settings_input_hdmi: unquote('"\\ec1f"');
$i-settings_input_svideo: unquote('"\\ec20"');
$i-settings_overscan: unquote('"\\ec21"');
$i-settings_phone: unquote('"\\ec22"');
$i-settings_power: unquote('"\\ec23"');
$i-settings_remote: unquote('"\\ec24"');
$i-settings_voice: unquote('"\\ec25"');
$i-shop: unquote('"\\ec26"');
$i-shop_two: unquote('"\\ec27"');
$i-shopping_basket: unquote('"\\ec28"');
$i-speaker_notes: unquote('"\\ec29"');
$i-spellcheck: unquote('"\\ec2a"');
$i-stars: unquote('"\\ec2b"');
$i-subject: unquote('"\\ec2c"');
$i-supervisor_account: unquote('"\\ec2d"');
$i-swap_horiz: unquote('"\\ec2e"');
$i-swap_vert: unquote('"\\ec2f"');
$i-swap_vertical_circle: unquote('"\\ec30"');
$i-system_update_tv: unquote('"\\ec31"');
$i-tab: unquote('"\\ec32"');
$i-tab_unselected: unquote('"\\ec33"');
$i-thumb_down: unquote('"\\ec34"');
$i-thumb_up: unquote('"\\ec35"');
$i-thumbs_up_down: unquote('"\\ec36"');
$i-toc: unquote('"\\ec37"');
$i-today: unquote('"\\ec38"');
$i-toll: unquote('"\\ec39"');
$i-track_changes: unquote('"\\ec3a"');
$i-translate: unquote('"\\ec3b"');
$i-trending_down: unquote('"\\ec3c"');
$i-trending_neutral: unquote('"\\ec3d"');
$i-trending_up: unquote('"\\ec3e"');
$i-verified_user: unquote('"\\ec3f"');
$i-view_agenda: unquote('"\\ec40"');
$i-view_array: unquote('"\\ec41"');
$i-view_carousel: unquote('"\\ec42"');
$i-view_column: unquote('"\\ec43"');
$i-view_day: unquote('"\\ec44"');
$i-view_headline: unquote('"\\ec45"');
$i-view_list: unquote('"\\ec46"');
$i-view_module: unquote('"\\ec47"');
$i-view_quilt: unquote('"\\ec48"');
$i-view_stream: unquote('"\\ec49"');
$i-view_week: unquote('"\\ec4a"');
$i-visibility_off: unquote('"\\ec4b"');
$i-card_membership: unquote('"\\ec4c"');
$i-card_travel: unquote('"\\ec4d"');
$i-work: unquote('"\\ec4e"');
$i-youtube_searched_for: unquote('"\\ec4f"');
$i-eject: unquote('"\\ec50"');
$i-camera_enhance: unquote('"\\ec51"');
$i-help_outline: unquote('"\\ec52"');
$i-reorder: unquote('"\\ec53"');
$i-zoom_in: unquote('"\\ec54"');
$i-zoom_out: unquote('"\\ec55"');
$i-http: unquote('"\\ec56"');
$i-event_seat: unquote('"\\ec57"');
$i-flight_land: unquote('"\\ec58"');
$i-flight_takeoff: unquote('"\\ec59"');
$i-play_for_work: unquote('"\\ec5a"');
$i-gif: unquote('"\\ec5b"');
$i-indeterminate_check_box: unquote('"\\ec5c"');
$i-offline_pin: unquote('"\\ec5d"');
$i-all_out: unquote('"\\ec5e"');
$i-copyright: unquote('"\\ec5f"');
$i-fingerprint: unquote('"\\ec60"');
$i-gavel: unquote('"\\ec61"');
$i-lightbulb_outline: unquote('"\\ec62"');
$i-picture_in_picture_alt: unquote('"\\ec63"');
$i-important_devices: unquote('"\\ec64"');
$i-touch_app: unquote('"\\ec65"');
$i-accessible: unquote('"\\ec66"');
$i-compare_arrows: unquote('"\\ec67"');
$i-date_range: unquote('"\\ec68"');
$i-donut_large: unquote('"\\ec69"');
$i-donut_small: unquote('"\\ec6a"');
$i-line_style: unquote('"\\ec6b"');
$i-line_weight: unquote('"\\ec6c"');
$i-motorcycle: unquote('"\\ec6d"');
$i-opacity: unquote('"\\ec6e"');
$i-pets: unquote('"\\ec6f"');
$i-pregnant_woman: unquote('"\\ec70"');
$i-record_voice_over: unquote('"\\ec71"');
$i-rounded_corner: unquote('"\\ec72"');
$i-rowing: unquote('"\\ec73"');
$i-timeline: unquote('"\\ec74"');
$i-update: unquote('"\\ec75"');
$i-watch_later: unquote('"\\ec76"');
$i-pan_tool: unquote('"\\ec77"');
$i-euro_symbol: unquote('"\\ec78"');
$i-g_translate: unquote('"\\ec79"');
$i-remove_shopping_cart: unquote('"\\ec7a"');
$i-restore_page: unquote('"\\ec7b"');
$i-speaker_notes_off: unquote('"\\ec7c"');
$i-delete_forever: unquote('"\\ec7d"');
$i-accessibility_new: unquote('"\\ec7e"');
$i-check_circle_outline: unquote('"\\ec7f"');
$i-delete_outline: unquote('"\\ec80"');
$i-done_outline: unquote('"\\ec81"');
$i-maximize: unquote('"\\ec82"');
$i-minimize: unquote('"\\ec83"');
$i-offline_bolt: unquote('"\\ec84"');
$i-swap_horizontal_circle: unquote('"\\ec85"');
$i-accessible_forward: unquote('"\\ec86"');
$i-calendar_today: unquote('"\\ec87"');
$i-calendar_view_day: unquote('"\\ec88"');
$i-label_important: unquote('"\\ec89"');
$i-restore_from_trash: unquote('"\\ec8a"');
$i-supervised_user_circle: unquote('"\\ec8b"');
$i-text_rotate_up: unquote('"\\ec8c"');
$i-text_rotate_vertical: unquote('"\\ec8d"');
$i-text_rotation_angledown: unquote('"\\ec8e"');
$i-text_rotation_angleup: unquote('"\\ec8f"');
$i-text_rotation_down: unquote('"\\ec90"');
$i-text_rotation_none: unquote('"\\ec91"');
$i-commute: unquote('"\\ec92"');
$i-arrow_right_alt: unquote('"\\ec93"');
$i-work_off: unquote('"\\ec94"');
$i-work_outline: unquote('"\\ec95"');
$i-drag_indicator: unquote('"\\ec96"');
$i-horizontal_split: unquote('"\\ec97"');
$i-label_important_outline: unquote('"\\ec98"');
$i-vertical_split: unquote('"\\ec99"');
$i-voice_over_off: unquote('"\\ec9a"');
$i-segment: unquote('"\\ec9b"');
$i-contact_support: unquote('"\\ec9c"');
$i-compress: unquote('"\\ec9d"');
$i-filter_list_alt: unquote('"\\ec9e"');
$i-expand: unquote('"\\ec9f"');
$i-edit_off: unquote('"\\eca0"');
$i-10k: unquote('"\\eca1"');
$i-10mp: unquote('"\\eca2"');
$i-11mp: unquote('"\\eca3"');
$i-12mp: unquote('"\\eca4"');
$i-13mp: unquote('"\\eca5"');
$i-14mp: unquote('"\\eca6"');
$i-15mp: unquote('"\\eca7"');
$i-16mp: unquote('"\\eca8"');
$i-17mp: unquote('"\\eca9"');
$i-18mp: unquote('"\\ecaa"');
$i-19mp: unquote('"\\ecab"');
$i-1k: unquote('"\\ecac"');
$i-1k_plus: unquote('"\\ecad"');
$i-20mp: unquote('"\\ecae"');
$i-21mp: unquote('"\\ecaf"');
$i-22mp: unquote('"\\ecb0"');
$i-23mp: unquote('"\\ecb1"');
$i-24mp: unquote('"\\ecb2"');
$i-2k: unquote('"\\ecb3"');
$i-2k_plus: unquote('"\\ecb4"');
$i-2mp: unquote('"\\ecb5"');
$i-3k: unquote('"\\ecb6"');
$i-3k_plus: unquote('"\\ecb7"');
$i-3mp: unquote('"\\ecb8"');
$i-4k_plus: unquote('"\\ecb9"');
$i-4mp: unquote('"\\ecba"');
$i-5k: unquote('"\\ecbb"');
$i-5k_plus: unquote('"\\ecbc"');
$i-5mp: unquote('"\\ecbd"');
$i-6k: unquote('"\\ecbe"');
$i-6k_plus: unquote('"\\ecbf"');
$i-6mp: unquote('"\\ecc0"');
$i-7k: unquote('"\\ecc1"');
$i-7k_plus: unquote('"\\ecc2"');
$i-7mp: unquote('"\\ecc3"');
$i-8k: unquote('"\\ecc4"');
$i-8k_plus: unquote('"\\ecc5"');
$i-8mp: unquote('"\\ecc6"');
$i-9k: unquote('"\\ecc7"');
$i-9k_plus: unquote('"\\ecc8"');
$i-9mp: unquote('"\\ecc9"');
$i-account_tree: unquote('"\\ecca"');
$i-add_chart: unquote('"\\eccb"');
$i-add_ic_call: unquote('"\\eccc"');
$i-add_moderator: unquote('"\\eccd"');
$i-all_inbox: unquote('"\\ecce"');
$i-approval: unquote('"\\eccf"');
$i-assistant_direction: unquote('"\\ecd0"');
$i-assistant_navigation: unquote('"\\ecd1"');
$i-bookmarks: unquote('"\\ecd2"');
$i-bus_alert: unquote('"\\ecd3"');
$i-cases: unquote('"\\ecd4"');
$i-circle_notifications: unquote('"\\ecd5"');
$i-closed_caption_off: unquote('"\\ecd6"');
$i-connected_tv: unquote('"\\ecd7"');
$i-dangerous: unquote('"\\ecd8"');
$i-dashboard_customize: unquote('"\\ecd9"');
$i-desktop_access_disabled: unquote('"\\ecda"');
$i-drive_file_move_outline: unquote('"\\ecdb"');
$i-drive_file_rename_outline: unquote('"\\ecdc"');
$i-drive_folder_upload: unquote('"\\ecdd"');
$i-duo: unquote('"\\ecde"');
$i-explore_off: unquote('"\\ecdf"');
$i-file_download_done: unquote('"\\ece0"');
$i-rtt: unquote('"\\ece1"');
$i-grid_view: unquote('"\\ece2"');
$i-hail: unquote('"\\ece3"');
$i-home_filled: unquote('"\\ece4"');
$i-imagesearch_roller: unquote('"\\ece5"');
$i-label_off: unquote('"\\ece6"');
$i-library_add_check: unquote('"\\ece7"');
$i-logout: unquote('"\\ece8"');
$i-margin: unquote('"\\ece9"');
$i-mark_as_unread: unquote('"\\ecea"');
$i-menu_open: unquote('"\\eceb"');
$i-mp: unquote('"\\ecec"');
$i-offline_share: unquote('"\\eced"');
$i-padding: unquote('"\\ecee"');
$i-panorama_photosphere: unquote('"\\ecef"');
$i-panorama_photosphere_select: unquote('"\\ecf0"');
$i-person_add_disabled: unquote('"\\ecf1"');
$i-phone_disabled: unquote('"\\ecf2"');
$i-phone_enabled: unquote('"\\ecf3"');
$i-pivot_table_chart: unquote('"\\ecf4"');
$i-print_disabled: unquote('"\\ecf5"');
$i-railway_alert: unquote('"\\ecf6"');
$i-recommend: unquote('"\\ecf7"');
$i-remove_done: unquote('"\\ecf8"');
$i-remove_moderator: unquote('"\\ecf9"');
$i-repeat_on: unquote('"\\ecfa"');
$i-repeat_one_on: unquote('"\\ecfb"');
$i-replay_circle_filled: unquote('"\\ecfc"');
$i-reset_tv: unquote('"\\ecfd"');
$i-sd: unquote('"\\ecfe"');
$i-shield: unquote('"\\ecff"');
$i-shuffle_on: unquote('"\\ed00"');
$i-speed: unquote('"\\ed01"');
$i-stacked_bar_chart: unquote('"\\ed02"');
$i-stream: unquote('"\\ed03"');
$i-swipe: unquote('"\\ed04"');
$i-switch_account: unquote('"\\ed05"');
$i-tag: unquote('"\\ed06"');
$i-thumb_down_off_alt: unquote('"\\ed07"');
$i-thumb_up_off_alt: unquote('"\\ed08"');
$i-toggle_off: unquote('"\\ed09"');
$i-toggle_on: unquote('"\\ed0a"');
$i-two_wheeler: unquote('"\\ed0b"');
$i-upload_file: unquote('"\\ed0c"');
$i-view_in_ar: unquote('"\\ed0d"');
$i-waterfall_chart: unquote('"\\ed0e"');
$i-wb_shade: unquote('"\\ed0f"');
$i-wb_twighlight: unquote('"\\ed10"');
$i-home_work: unquote('"\\ed11"');
$i-schedule_send: unquote('"\\ed12"');
$i-bolt: unquote('"\\ed13"');
$i-send_and_archive: unquote('"\\ed14"');
$i-workspaces_filled: unquote('"\\ed15"');
$i-file_present: unquote('"\\ed16"');
$i-workspaces_outline: unquote('"\\ed17"');
$i-fit_screen: unquote('"\\ed18"');
$i-saved_search: unquote('"\\ed19"');
$i-storefront: unquote('"\\ed1a"');
$i-amp_stories: unquote('"\\ed1b"');
$i-dynamic_feed: unquote('"\\ed1c"');
$i-euro: unquote('"\\ed1d"');
$i-height: unquote('"\\ed1e"');
$i-policy: unquote('"\\ed1f"');
$i-sync_alt: unquote('"\\ed20"');
$i-menu_book: unquote('"\\ed21"');
$i-emoji_flags: unquote('"\\ed22"');
$i-emoji_food_beverage: unquote('"\\ed23"');
$i-emoji_nature: unquote('"\\ed24"');
$i-emoji_people: unquote('"\\ed25"');
$i-emoji_symbols: unquote('"\\ed26"');
$i-emoji_transportation: unquote('"\\ed27"');
$i-post_add: unquote('"\\ed28"');
$i-people_alt: unquote('"\\ed29"');
$i-emoji_emotions: unquote('"\\ed2a"');
$i-emoji_events: unquote('"\\ed2b"');
$i-emoji_objects: unquote('"\\ed2c"');
$i-sports_basketball: unquote('"\\ed2d"');
$i-sports_cricket: unquote('"\\ed2e"');
$i-sports_esports: unquote('"\\ed2f"');
$i-sports_football: unquote('"\\ed30"');
$i-sports_golf: unquote('"\\ed31"');
$i-sports_hockey: unquote('"\\ed32"');
$i-sports_mma: unquote('"\\ed33"');
$i-sports_motorsports: unquote('"\\ed34"');
$i-sports_rugby: unquote('"\\ed35"');
$i-sports_soccer: unquote('"\\ed36"');
$i-sports: unquote('"\\ed37"');
$i-sports_volleyball: unquote('"\\ed38"');
$i-sports_tennis: unquote('"\\ed39"');
$i-sports_handball: unquote('"\\ed3a"');
$i-sports_kabaddi: unquote('"\\ed3b"');
$i-eco: unquote('"\\ed3c"');
$i-museum: unquote('"\\ed3d"');
$i-flip_camera_android: unquote('"\\ed3e"');
$i-flip_camera_ios: unquote('"\\ed3f"');
$i-cancel_schedule_send: unquote('"\\ed40"');
$i-apartment: unquote('"\\ed41"');
$i-bathtub: unquote('"\\ed42"');
$i-deck: unquote('"\\ed43"');
$i-fireplace: unquote('"\\ed44"');
$i-house: unquote('"\\ed45"');
$i-king_bed: unquote('"\\ed46"');
$i-nights_stay: unquote('"\\ed47"');
$i-outdoor_grill: unquote('"\\ed48"');
$i-single_bed: unquote('"\\ed49"');
$i-square_foot: unquote('"\\ed4a"');
$i-double_arrow: unquote('"\\ed4b"');
$i-sports_baseball: unquote('"\\ed4c"');
$i-attractions: unquote('"\\ed4d"');
$i-bakery_dining: unquote('"\\ed4e"');
$i-breakfast_dining: unquote('"\\ed4f"');
$i-car_rental: unquote('"\\ed50"');
$i-car_repair: unquote('"\\ed51"');
$i-dinner_dining: unquote('"\\ed52"');
$i-dry_cleaning: unquote('"\\ed53"');
$i-hardware: unquote('"\\ed54"');
$i-liquor: unquote('"\\ed55"');
$i-lunch_dining: unquote('"\\ed56"');
$i-nightlife: unquote('"\\ed57"');
$i-park: unquote('"\\ed58"');
$i-ramen_dining: unquote('"\\ed59"');
$i-celebration: unquote('"\\ed5a"');
$i-theater_comedy: unquote('"\\ed5b"');
$i-badge: unquote('"\\ed5c"');
$i-festival: unquote('"\\ed5d"');
$i-icecream: unquote('"\\ed5e"');
$i-volunteer_activism: unquote('"\\ed5f"');
$i-contactless: unquote('"\\ed60"');
$i-delivery_dining: unquote('"\\ed61"');
$i-brunch_dining: unquote('"\\ed62"');
$i-takeout_dining: unquote('"\\ed63"');
$i-ac_unit: unquote('"\\ed64"');
$i-airport_shuttle: unquote('"\\ed65"');
$i-all_inclusive: unquote('"\\ed66"');
$i-beach_access: unquote('"\\ed67"');
$i-business_center: unquote('"\\ed68"');
$i-casino: unquote('"\\ed69"');
$i-child_care: unquote('"\\ed6a"');
$i-child_friendly: unquote('"\\ed6b"');
$i-fitness_center: unquote('"\\ed6c"');
$i-golf_course: unquote('"\\ed6d"');
$i-hot_tub: unquote('"\\ed6e"');
$i-kitchen: unquote('"\\ed6f"');
$i-pool: unquote('"\\ed70"');
$i-room_service: unquote('"\\ed71"');
$i-smoke_free: unquote('"\\ed72"');
$i-smoking_rooms: unquote('"\\ed73"');
$i-spa: unquote('"\\ed74"');
$i-no_meeting_room: unquote('"\\ed75"');
$i-meeting_room: unquote('"\\ed76"');
$i-goat: unquote('"\\ed77"');
$i-5g: unquote('"\\ed78"');
$i-ad_units: unquote('"\\ed79"');
$i-add_business: unquote('"\\ed7a"');
$i-add_location_alt: unquote('"\\ed7b"');
$i-add_road: unquote('"\\ed7c"');
$i-add_to_drive: unquote('"\\ed7d"');
$i-addchart: unquote('"\\ed7e"');
$i-agriculture: unquote('"\\ed80"');
$i-alt_route: unquote('"\\ed81"');
$i-analytics: unquote('"\\ed82"');
$i-anchor: unquote('"\\ed83"');
$i-animation: unquote('"\\ed84"');
$i-api: unquote('"\\ed85"');
$i-app_blocking: unquote('"\\ed86"');
$i-app_registration: unquote('"\\ed87"');
$i-app_settings_alt: unquote('"\\ed88"');
$i-architecture: unquote('"\\ed89"');
$i-arrow_circle_down: unquote('"\\ed8a"');
$i-arrow_circle_up: unquote('"\\ed8b"');
$i-article: unquote('"\\ed8c"');
$i-attach_email: unquote('"\\ed8d"');
$i-auto_awesome: unquote('"\\ed8e"');
$i-auto_awesome_mosaic: unquote('"\\ed8f"');
$i-auto_awesome_motion: unquote('"\\ed90"');
$i-auto_delete: unquote('"\\ed91"');
$i-auto_fix_high: unquote('"\\ed92"');
$i-auto_fix_normal: unquote('"\\ed93"');
$i-auto_fix_off: unquote('"\\ed94"');
$i-auto_stories: unquote('"\\ed95"');
$i-baby_changing_station: unquote('"\\ed96"');
$i-backpack: unquote('"\\ed97"');
$i-backup_table: unquote('"\\ed98"');
$i-batch_prediction: unquote('"\\ed99"');
$i-bedtime: unquote('"\\ed9a"');
$i-bento: unquote('"\\ed9b"');
$i-bike_scooter: unquote('"\\ed9c"');
$i-biotech: unquote('"\\ed9d"');
$i-block_flipped: unquote('"\\ed9e"');
$i-browser_not_supported: unquote('"\\ed9f"');
$i-build_circle: unquote('"\\eda0"');
$i-calculate: unquote('"\\eda1"');
$i-campaign: unquote('"\\eda2"');
$i-carpenter: unquote('"\\eda3"');
$i-cast_for_education: unquote('"\\eda4"');
$i-charging_station: unquote('"\\eda5"');
$i-checkroom: unquote('"\\eda6"');
$i-circle: unquote('"\\eda7"');
$i-cleaning_services: unquote('"\\eda8"');
$i-close_fullscreen: unquote('"\\eda9"');
$i-closed_caption_disabled: unquote('"\\edaa"');
$i-comment_bank: unquote('"\\edab"');
$i-construction: unquote('"\\edac"');
$i-corporate_fare: unquote('"\\edad"');
$i-countertops: unquote('"\\edae"');
$i-design_services: unquote('"\\edaf"');
$i-directions_off: unquote('"\\edb0"');
$i-dirty_lens: unquote('"\\edb1"');
$i-do_not_step: unquote('"\\edb2"');
$i-do_not_touch: unquote('"\\edb3"');
$i-domain_verification: unquote('"\\edb4"');
$i-drive_file_move: unquote('"\\edb5"');
$i-dry: unquote('"\\edb6"');
$i-dynamic_form: unquote('"\\edb7"');
$i-east: unquote('"\\edb8"');
$i-edit_road: unquote('"\\edb9"');
$i-electric_bike: unquote('"\\edba"');
$i-electric_car: unquote('"\\edbb"');
$i-electric_moped: unquote('"\\edbc"');
$i-electric_rickshaw: unquote('"\\edbd"');
$i-electric_scooter: unquote('"\\edbe"');
$i-electrical_services: unquote('"\\edbf"');
$i-elevator: unquote('"\\edc0"');
$i-engineering: unquote('"\\edc1"');
$i-escalator: unquote('"\\edc2"');
$i-escalator_warning: unquote('"\\edc3"');
$i-face_retouching_natural: unquote('"\\edc4"');
$i-fact_check: unquote('"\\edc5"');
$i-family_restroom: unquote('"\\edc6"');
$i-fence: unquote('"\\edc7"');
$i-filter_alt: unquote('"\\edc8"');
$i-fire_extinguisher: unquote('"\\edc9"');
$i-flaky: unquote('"\\edca"');
$i-food_bank: unquote('"\\edcb"');
$i-forward_to_inbox: unquote('"\\edcc"');
$i-foundation: unquote('"\\edcd"');
$i-grading: unquote('"\\edce"');
$i-grass: unquote('"\\edcf"');
$i-handyman: unquote('"\\edd0"');
$i-hdr_enhanced_select: unquote('"\\edd1"');
$i-hearing_disabled: unquote('"\\edd2"');
$i-help_center: unquote('"\\edd3"');
$i-highlight_alt: unquote('"\\edd4"');
$i-history_edu: unquote('"\\edd5"');
$i-history_toggle_off: unquote('"\\edd6"');
$i-home_repair_service: unquote('"\\edd7"');
$i-horizontal_rule: unquote('"\\edd8"');
$i-hourglass_bottom: unquote('"\\edd9"');
$i-hourglass_disabled: unquote('"\\edda"');
$i-hourglass_top: unquote('"\\eddb"');
$i-house_siding: unquote('"\\eddc"');
$i-hvac: unquote('"\\eddd"');
$i-image_not_supported: unquote('"\\edde"');
$i-insights: unquote('"\\eddf"');
$i-integration_instructions: unquote('"\\ede0"');
$i-ios_share: unquote('"\\ede1"');
$i-legend_toggle: unquote('"\\ede2"');
$i-local_fire_department: unquote('"\\ede3"');
$i-local_police: unquote('"\\ede4"');
$i-location_pin: unquote('"\\ede5"');
$i-lock_clock: unquote('"\\ede6"');
$i-login: unquote('"\\ede7"');
$i-maps_ugc: unquote('"\\ede8"');
$i-mark_chat_read: unquote('"\\ede9"');
$i-mark_chat_unread: unquote('"\\edea"');
$i-mark_email_read: unquote('"\\edeb"');
$i-mark_email_unread: unquote('"\\edec"');
$i-mediation: unquote('"\\eded"');
$i-medical_services: unquote('"\\edee"');
$i-mic_external_off: unquote('"\\edef"');
$i-mic_external_on: unquote('"\\edf0"');
$i-microwave: unquote('"\\edf1"');
$i-military_tech: unquote('"\\edf2"');
$i-miscellaneous_services: unquote('"\\edf3"');
$i-model_training: unquote('"\\edf4"');
$i-monitor: unquote('"\\edf5"');
$i-moped: unquote('"\\edf6"');
$i-more_time: unquote('"\\edf7"');
$i-motion_photos_off: unquote('"\\edf8"');
$i-motion_photos_on: unquote('"\\edf9"');
$i-motion_photos_paused: unquote('"\\edfa"');
$i-multiple_stop: unquote('"\\edfb"');
$i-nat: unquote('"\\edfc"');
$i-near_me_disabled: unquote('"\\edfd"');
$i-next_plan: unquote('"\\edfe"');
$i-night_shelter: unquote('"\\edff"');
$i-nightlight_round: unquote('"\\ee00"');
$i-no_cell: unquote('"\\ee01"');
$i-no_drinks: unquote('"\\ee02"');
$i-no_flash: unquote('"\\ee03"');
$i-no_food: unquote('"\\ee04"');
$i-no_meals: unquote('"\\ee05"');
$i-no_photography: unquote('"\\ee06"');
$i-no_stroller: unquote('"\\ee07"');
$i-no_transfer: unquote('"\\ee08"');
$i-north: unquote('"\\ee09"');
$i-north_east: unquote('"\\ee0a"');
$i-north_west: unquote('"\\ee0b"');
$i-not_accessible: unquote('"\\ee0c"');
$i-not_started: unquote('"\\ee0d"');
$i-online_prediction: unquote('"\\ee0e"');
$i-open_in_full: unquote('"\\ee0f"');
$i-outbox: unquote('"\\ee10"');
$i-outgoing_mail: unquote('"\\ee11"');
$i-outlet: unquote('"\\ee12"');
$i-panorama_horizontal_select: unquote('"\\ee13"');
$i-panorama_vertical_select: unquote('"\\ee14"');
$i-panorama_wide_angle_select: unquote('"\\ee15"');
$i-payments: unquote('"\\ee16"');
$i-pedal_bike: unquote('"\\ee17"');
$i-pending: unquote('"\\ee18"');
$i-pending_actions: unquote('"\\ee19"');
$i-person_add_alt: unquote('"\\ee1a"');
$i-person_add_alt_1: unquote('"\\ee1b"');
$i-person_remove: unquote('"\\ee1c"');
$i-person_search: unquote('"\\ee1d"');
$i-pest_control: unquote('"\\ee1e"');
$i-pest_control_rodent: unquote('"\\ee1f"');
$i-photo_camera_back: unquote('"\\ee20"');
$i-photo_camera_front: unquote('"\\ee21"');
$i-plagiarism: unquote('"\\ee22"');
$i-play_disabled: unquote('"\\ee23"');
$i-plumbing: unquote('"\\ee24"');
$i-point_of_sale: unquote('"\\ee25"');
$i-preview: unquote('"\\ee26"');
$i-privacy_tip: unquote('"\\ee27"');
$i-psychology: unquote('"\\ee28"');
$i-public_off: unquote('"\\ee29"');
$i-push_pin: unquote('"\\ee2a"');
$i-qr_code: unquote('"\\ee2b"');
$i-qr_code_scanner: unquote('"\\ee2c"');
$i-quickreply: unquote('"\\ee2d"');
$i-read_more: unquote('"\\ee2e"');
$i-receipt_long: unquote('"\\ee2f"');
$i-request_quote: unquote('"\\ee30"');
$i-rice_bowl: unquote('"\\ee31"');
$i-roofing: unquote('"\\ee32"');
$i-room_preferences: unquote('"\\ee33"');
$i-rule: unquote('"\\ee34"');
$i-rule_folder: unquote('"\\ee35"');
$i-run_circle: unquote('"\\ee36"');
$i-science: unquote('"\\ee37"');
$i-screen_search_desktop: unquote('"\\ee38"');
$i-search_off: unquote('"\\ee39"');
$i-self_improvement: unquote('"\\ee3a"');
$i-sensor_door: unquote('"\\ee3b"');
$i-sensor_window: unquote('"\\ee3c"');
$i-set_meal: unquote('"\\ee3d"');
$i-shopping_bag: unquote('"\\ee3e"');
$i-signal_cellular_0_bar: unquote('"\\ee3f"');
$i-signal_wifi_0_bar: unquote('"\\ee40"');
$i-smart_button: unquote('"\\ee41"');
$i-snippet_folder: unquote('"\\ee42"');
$i-soap: unquote('"\\ee43"');
$i-source: unquote('"\\ee44"');
$i-south: unquote('"\\ee45"');
$i-south_east: unquote('"\\ee46"');
$i-south_west: unquote('"\\ee47"');
$i-sports_bar: unquote('"\\ee48"');
$i-stairs: unquote('"\\ee49"');
$i-star_outline1: unquote('"\\ee4a"');
$i-star_rate: unquote('"\\ee4b"');
$i-sticky_note_2: unquote('"\\ee4c"');
$i-stop_circle: unquote('"\\ee4d"');
$i-stroller: unquote('"\\ee4e"');
$i-subscript: unquote('"\\ee4f"');
$i-subtitles_off: unquote('"\\ee50"');
$i-superscript: unquote('"\\ee51"');
$i-support: unquote('"\\ee52"');
$i-support_agent: unquote('"\\ee53"');
$i-switch_left: unquote('"\\ee54"');
$i-switch_right: unquote('"\\ee55"');
$i-table_rows: unquote('"\\ee56"');
$i-table_view: unquote('"\\ee57"');
$i-tapas: unquote('"\\ee58"');
$i-taxi_alert: unquote('"\\ee59"');
$i-text_snippet: unquote('"\\ee5a"');
$i-tour: unquote('"\\ee5b"');
$i-tty: unquote('"\\ee5c"');
$i-umbrella: unquote('"\\ee5d"');
$i-upgrade: unquote('"\\ee5e"');
$i-verified: unquote('"\\ee5f"');
$i-video_settings: unquote('"\\ee60"');
$i-view_sidebar: unquote('"\\ee61"');
$i-wash: unquote('"\\ee62"');
$i-water_damage: unquote('"\\ee63"');
$i-west: unquote('"\\ee64"');
$i-wheelchair_pickup: unquote('"\\ee65"');
$i-wifi_calling: unquote('"\\ee66"');
$i-wifi_protected_setup: unquote('"\\ee67"');
$i-wine_bar: unquote('"\\ee68"');
$i-wrong_location: unquote('"\\ee69"');
$i-wysiwyg: unquote('"\\ee6a"');
$i-leaderboard: unquote('"\\ee6b"');
$i-6_ft_apart: unquote('"\\ee6c"');
$i-book_online: unquote('"\\ee6d"');
$i-clean_hands: unquote('"\\ee6e"');
$i-connect_without_contact: unquote('"\\ee6f"');
$i-coronavirus: unquote('"\\ee70"');
$i-elderly: unquote('"\\ee71"');
$i-follow_the_signs: unquote('"\\ee72"');
$i-leave_bags_at_home: unquote('"\\ee73"');
$i-masks: unquote('"\\ee74"');
$i-reduce_capacity: unquote('"\\ee75"');
$i-sanitizer: unquote('"\\ee76"');
$i-send_to_mobile: unquote('"\\ee77"');
$i-sick: unquote('"\\ee78"');
$i-add_task: unquote('"\\ee79"');
$i-contact_page: unquote('"\\ee7a"');
$i-disabled_by_default: unquote('"\\ee7b"');
$i-facebook: unquote('"\\ee7c"');
$i-groups: unquote('"\\ee7d"');
$i-luggage: unquote('"\\ee7e"');
$i-no_backpack: unquote('"\\ee7f"');
$i-no_luggage: unquote('"\\ee80"');
$i-outbond: unquote('"\\ee81"');
$i-published_with_changes: unquote('"\\ee82"');
$i-request_page: unquote('"\\ee83"');
$i-stacked_line_chart: unquote('"\\ee84"');
$i-unpublished: unquote('"\\ee85"');
$i-align_horizontal_center: unquote('"\\ee86"');
$i-align_horizontal_left: unquote('"\\ee87"');
$i-align_horizontal_right: unquote('"\\ee88"');
$i-align_vertical_bottom: unquote('"\\ee89"');
$i-align_vertical_center: unquote('"\\ee8a"');
$i-align_vertical_top: unquote('"\\ee8b"');
$i-horizontal_distribute: unquote('"\\ee8c"');
$i-qr_code_2: unquote('"\\ee8d"');
$i-update_disabled: unquote('"\\ee8e"');
$i-vertical_distribute: unquote('"\\ee8f"');
$i-hospital-o: unquote('"\\ee92"');

