::selection,
::-moz-selection {
  background: cssvar($theme 0.3, selection);
}

* {
  @include scrollbar(8px, cssvar($theme, scroll), cssvar($theme 0.1, scroll));
  &:before,
  &:after {
    pointer-events: none;
  }
}

html,
body {
  width: 100%;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0;
  font-size: $baseFontSize;
  accent-color: cssvar($theme, accent);
  @if variable-exists(minWidth) {
    min-width: $minWidth;
  }
  @if variable-exists(maxWidth) {
    max-width: $maxWidth;
  }
}

html {
  overflow: scroll; // /* has to be scroll, not auto
  overflow-y: scroll;
  overflow-x: hidden;
  text-rendering: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  // margin: 0 auto;
  // max-width: clamp(320px, 90%, 1000px);
  color: cssvar($theme, text);
  background: cssvar($theme, bg);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: cssvar($theme, fontFamily);
  // -webkit-backface-visibility: hidden;
}

small,
.small {
  line-height: 135%;
  font-size: 0.8182em; // 18px
}

strong,
.strong {
  @include font($fontB);
}

label,
.label {
  font-size: 16px;
  line-height: 100%;
  @include font($fontB);
  // letter-spacing: -0.02em;
}

a,
button {
  @extend %animateFast;
  & > * {
    pointer-events: none;
  }
}

a {
  color: cssvar($theme, link);
  // &,
  // & > * {
  //   outline: none;
  // }
  & > * {
    pointer-events: none;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: cssvar($theme, link);
  }
}

b,
strong {
  font-weight: normal;
  @include font($fontB);
}

svg {
  &.defs {
    width: 0;
    height: 0;
    position: absolute;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

// fieldset {
//   overflow: hidden;
//   padding: remc(7px) remc(20px);
//   border: rgbacssvar$color, dark), 0.36) remc(1px) solid;
// }

// .container-fluid {
//   &.pageWrapper {
//     padding: 0;
//     margin: 0 auto;
//     max-width: 1200px;
//     @include box-shadow(0 0 remc(15px) rgba(cssvar($color, dark), 0.5));
//   }
// }

mark,
.mark {
  line-height: 100%;
  border-radius: 4px;
  display: inline-block;
  box-sizing: content-box;
  box-decoration-break: clone;
  padding: 2.5px em(16px, 70px);
  background: cssvar($theme, marker);
  border: 0.5px solid cssvar($color 0.16, dark);
}

picture {
  display: inline-block;
}

figure {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: cssvar($global app-base, titleFont);
  small,
  .small {
    font-size: 0.575em;
  }
}

h1,
.h1 {
  font-size: 70px;
  line-height: 100%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h2,
.h2 {
  font-size: 48px;
  line-height: 125%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h3,
.h3 {
  font-size: 48px;
  line-height: 135%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h4,
.h4 {
  font-size: 36px;
  line-height: 135%;
  @include font($fontM);
  strong {
    @include font($fontB);
  }
  &.normal {
    @include font($font);
  }
}

h5,
.h5 {
  font-size: 28px;
  line-height: 100%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h6,
.h6 {
  font-size: 28px;
  line-height: 135%;
  @include font($fontM);
  strong {
    @include font($fontB);
  }
  &.normal {
    @include font($font);
  }
}

.fw,
.fh {
  position: relative;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

video.asBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  pointer-events: none;
}

.container {
  &,
  &-fluid {
    --bs-gutter-x: 40px;
  }
}

// @include media-breakpoint-down(map-get($global, device-switch)) {
//   html,
//   body {
//     font-size: 20px;
//     line-height: 160%;
//     letter-spacing: 0;
//   }

//   small,
//   .small {
//     font-size: 18px;
//     line-height: 135%;
//   }

//   h1,
//   .h1 {
//     font-size: 48px;
//     line-height: 125%;
//   }

//   h2,
//   .h2 {
//     font-size: 36px;
//     line-height: 125%;
//   }

//   h3,
//   .h3 {
//     font-size: 30px;
//     line-height: 135%;
//   }

//   h4,
//   .h4 {
//     font-size: 26px;
//     line-height: 135%;
//   }

//   h5,
//   .h5 {
//     font-size: 28px;
//     line-height: 100%;
//   }

//   h6,
//   .h6 {
//     font-size: 26px;
//     line-height: 135%;
//   }
// }
