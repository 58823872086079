@mixin new-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: "#{$font-family}";
		src: url("#{$font-filename}.eot");
		src:
			url("#{$font-filename}.eot?#iefix") format("embedded-opentype"),
			url("#{$font-filename}.woff") format("woff"),
			url("#{$font-filename}.ttf") format("truetype"),
			url("#{$font-filename}.svg##{$font-family}") format("svg");
		font-style: $font-style;
		font-weight: $font-weight;
		font-stretch: $font-stretch;
	}
}

@mixin font($font) {
	@if type-of($font) == number {
		font-weight: $font;
	} @else if type-of($font) == list {
		@if (type-of(nth($font, 1)) == number) {
			font-weight: var(#{nth($font, 2)}, #{nth($font, 1)});
		} @else {
			font-family: var(#{nth($font, 2)}, #{nth($font, 1)});
		}
	} @else {
		font-family: $font;
	}
}

@mixin langFont($style: 400) {
	@if $style == "bold" {
		@include font($fontFamilyBold);
	} @else {
		@include font($fontFamily);
	}
}