@use "sass:map";
@use "sass:math";
@use "sass:meta";
@use "sass:list";
@use "sass:string";

// Create CSS Vars
@mixin set-css-vars($prefix, $map) {
  @each $key, $value in $map {
    @if (type-of($value) != "map") {
      --#{$prefix}-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$prefix}-#{$key}-rgb: #{toRGBList($value)};
      }
    } @else {
      @include set-css-vars(#{$prefix}-#{$key}, $value);
    }
  }
}

@function css-var($options, $keys...) {
  // Extract map, alpha, and prefix from options
  $map: null;
  $alpha: null;
  $prefix: "app";

  @if (type-of($options) == "map") {
    $map: $options;
  } @else {
    $map: nth($options, 1);
  }

  @each $option in $options {
    @if type-of($option) == "number" {
      $alpha: $option;
    } @else if type-of($option) == "string" {
      $prefix: $option;
    }
  }

  // Prefix CSS variable names
  $prefix: "--#{$prefix}";

  // Traverse keys to form the variable name and find the map value
  $name: $prefix;
  $currentMap: $map;
  @each $key in $keys {
    $name: "#{$name}-#{$key}";
    // @debug $currentMap, $key;
    $currentMap: map-get($currentMap, $key);

    @if $currentMap == null {
      @return null; // Return null if the key path is invalid
    }
  }

  // Determine the default value
  $defaultValue: $currentMap;

  // If the current map value is a list with length > 1
  @if type-of($currentMap) == "list" and length($currentMap) > 1 {
    $defaultValue: nth($currentMap, 1);
    $fallback: nth($currentMap, 2);
    @return unquote("var(#{$name}, #{$fallback})");
  }

  // If the current map value is a color and alpha is provided
  @if type-of($defaultValue) == "color" and $alpha != null {
    @return unquote("rgba(var(#{$name}-rgb, #{toRGBList($defaultValue)}, #{$alpha}), #{$alpha})");
  }

  // Default CSS variable reference
  @return unquote("var(#{$name}, #{$defaultValue})");
}
@function cssvar($options, $keys...) {
  @return css-var($options, $keys...);
}
// @debug css-var(($global app-base), titleFont);
// @debug css-var(($theme 0.3), loader);
// @debug css-var(($theme 0.5), colors, lighten, primary);

// @function cssVar($v, $t: $theme, $prefix: $cssVarPrefix) {
//   @if (type-of($t) == map) {
//     @if (list.length(map.get($t, $v)) > 1) {
//       @return var((--#{$prefix}-#{nth(map.get($t, $v), 2)}, nth(map.get($t, $v), 1)));
//     } @else {
//       @if (list.length($v) > 1) {
//         // @debug type-of(nth($v, 1)) AND type-of(nth($v, 2));
//         @if (type-of(nth($v, 2)) == number) {
//           @return rgba(var(--#{$prefix}-#{nth($v, 1)}-rgb, #{toRGBList(map.get($t, nth($v, 1)))}), nth($v, 2));
//         } @else {
//           @return var(--#{$prefix}-#{nth($v, 1)}, map.get($t, nth($v, 1)));
//         }
//       } @else {
//         @return var(--#{$prefix}-#{$v}, map.get($t, $v));
//       }
//     }
//   } @else if(type-of($t) == color) {
//     @if (list.length($v) > 1) {
//       @if (type-of(nth($v, 2)) == number) {
//         @return rgba(var(--#{$prefix}-#{nth($v, 1)}-rgb, #{toRGBList($t)}), nth($v, 2));
//       } @else {
//         @return var(--#{$prefix}-#{nth($v, 1)}, $t);
//       }
//     } @else {
//       @return var(--#{$prefix}-#{$v}, $t);
//     }
//   } @else {
//     @debug " :: value  : " $v;
//     @debug " :: theme  : " $t;
//     @debug " :: prefix : " $prefix;
//   }

//   @return "!ERROR";
// }
// /**! ======================== **** //
// ****         Example:         **** //
// **** ======================== **** //
// ****         *Normal*         **** //
// **** cssVar(dark, $color)     **** //
// ****         *RGBA*           **** //
// **** cssVar(dark 0.2, $color) **** //
// ****       *ShortHand*        **** //
// **** cssVar(dark 0.2, #000)   **** //
// **/

// @function cssVarAlpha($v, $alpha, $t: $theme, $prefix: $cssVarPrefix) {
//   @return cssVar($v $alpha, $t, $prefix);
// }

@function getCssVar($v, $t: $theme, $prefix: $cssVarPrefix) {
  @if (list.length(map.get($t, $v)) > 1) {
    @return var(--#{$prefix}-#{nth(map.get($t, $v), 2)}, nth(map.get($t, $v), 1));
  } @else {
    @return var(--#{$prefix}-#{$v}, map.get($t, $v));
  }
}
// getCssVar(accent, $color, app-colors);
// @debug getCssVar(accent, $color, app-colors);
