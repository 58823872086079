@import "theme";
@import "bsVars";

$baseFontSize: map-get($theme, baseFontSize);
$base-font-size: $baseFontSize;

$colorType: $color;

$matBorderRadius: map-get($global, border-radius);

// /* HD: 1920 X 1080 */
// $minWidth: 1080px;
// /* 4k: 3840 X 2160 */
// $maxWidth: 3840px;

$img: "../../assets/images";
$fonts: "../../assets/fonts";

$owl-image-path: "#{$img}/";

$icomoon-font-path: "#{$fonts}/icon";
