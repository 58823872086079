[data-bs-toggle="collapse"].collapser {
  display: block;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    @extend %animateFast;
  }
  &:before {
    @extend .i;
    z-index: 1;
    bottom: remc(0px);
    content: $i-add_circle;
    color: cssvar($theme, color);
  }
  &[aria-expanded="true"] {
    &:before {
      content: $i-remove_circle;
    }
  }
  &.withArrow {
    @include font($fontB);
    font-size: remc(29px);
    margin-top: remc(50px);
    margin-bottom: remc(35px);
    padding-bottom: remc(35px);
    line-height: em(30px, 29px);
    color: cssvar($theme, color);
    border-bottom: remc(7px) solid cssvar($theme, brdr);
    &:after,
    &:before {
      right: remc(80px);
    }
    &:before {
      margin-right: remc(24px);
      font-size: em(26.7px, 29px);
      content: $i-keyboard_arrow_down;
    }
    &:after {
      top: 100%;
      z-index: 0;
      width: remc(45px);
      height: remc(45px);
      border-style: solid;
      background: cssvar($theme, write);
      border-color: cssvar($theme, brdr);
      border-width: 0 remc(7px) remc(7px) 0;
      @include prefix(
        (
          transform: rotate(37deg) skewX(-20deg),
          transform-origin: right top
        ),
        webkit ms
      );
      margin-top: remc(5px);
    }
    &[aria-expanded="true"] {
      &:before {
        bottom: remc(-32px);
        content: $i-keyboard_arrow_down;
        @include prefix(
          (
            transform: rotate(180deg)
          ),
          webkit ms
        );
      }
      &:after {
        top: 100%;
        margin-top: remc(3px);
        @include prefix(
          (
            transform: rotate(37deg) skewX(-20deg) scale(-1) translate(remc(45px), remc(-45px))
          ),
          webkit ms
        );
      }
    }
  }
  &.plusMinus {
    &:before {
      content: $i-add_circle;
    }
    &[aria-expanded="true"] {
      &:before {
        content: $i-remove_circle;
      }
    }
  }
}
