$baseFontSize: 15px;
$fontFamily:
  #{"Rubik",
  sans-serif};
$scFontFamily:
  #{"Rubik",
  sans-serif};

$icomoon-font-family: "icon";

// COMMON FONT CHART
// -----------------
$fontUL: 300 --app-fontUL; // 'fontName ultralight', sans-serif;
$fontT: 300 --app-fontT; // 'fontName thin', sans-serif;
$fontL: 300 --app-fontL; // 'fontName light', sans-serif;
$font: 400 --app-font; // 'fontName regular', sans-serif;
$fontM: 500 --app-fontM; // 'fontName medium', sans-serif;
$fontSB: 600 --app-fontSB; // 'fontName semibold', sans-serif;
$fontB: 700 --app-fontB; // 'fontName bold', sans-serif;
$fontH: 800 --app-fontH; // 'fontName heavy', sans-serif;
$fontBK: 900 --app-fontBK; // 'fontName black', sans-serif;

$scFontUL: 300 --app-scFontUL; // 'scFontName ultralight', sans-serif;
$scFontT: 300 --app-scFontT; // 'scFontName thin', sans-serif;
$scFontL: 300 --app-scFontL; // 'scFontName light', sans-serif;
$scFont: 400 --app-scFont; // 'scFontName regular', sans-serif;
$scFontM: 500 --app-scFontM; // 'scFontName medium', sans-serif;
$scFontSB: 600 --app-scFontSB; // 'scFontName semibold', sans-serif;
$scFontB: 700 --app-scFontB; // 'scFontName bold', sans-serif;
$scFontH: 800 --app-scFontH; // 'scFontName heavy', sans-serif;
$scFontBK: 900 --app-scFontBK; // 'scFontName black', sans-serif;

$global: (
  // GLOBAL vars
  device-switch: xl,
  border-radius: 0px,
  border-radius-sm: 0px,
  border-radius-lg: 3px,
  titleFont: $scFontFamily
);

$theme: (
  // THEME vars
  font: nth($font, 1),
  fontL: nth($fontL, 1),
  fontT: nth($fontT, 1),
  fontM: nth($fontM, 1),
  fontB: nth($fontB, 1),
  fontH: nth($fontH, 1),
  fontUL: nth($fontUL, 1),
  fontSB: nth($fontSB, 1),
  fontBK: nth($fontBK, 1),
  fontFamily: $fontFamily,
  scFontFamily: $scFontFamily,
  baseFontSize: $baseFontSize,
  iconFont: $icomoon-font-family,
  bg: #f6f6f6,
  bgD: #e9ecef,
  text: #222,
  textL: #495057,
  brdr: #ced4da,
  write: #fff,
  link: #009efb,
  matBg: #edeef0,
  color: #7a92a3,
  accent: #ffbc35,
  success: #55ce63,
  scroll: #080575,
  loader: #080575,
  marker: #fff59f,
  selection: #2491cd,
  header: (
    text: #fff,
    bg: #080575,
    link: (
      color: #fff,
      hover: #fff,
      active: #fff
    ),
    dd: (
      bg: #fff,
      color: #222,
      hover: (
        bg: #080575,
        color: #fff
      ),
      active: (
        bg: #080575,
        color: #fff
      )
    ),
    offcanvas: (
      bg: #fff,
      text: #222,
      link: #222,
      active: (
        link: #080575
      )
    )
  ),
  footer: (
    text: #fff,
    bg: #2b2821,
    brdr: #c9c9c9,
    link: #080575
  )
);

$color: (
  dark: #000,
  light: #fff,
  info: #009efb,
  warning: #f30,
  accent: #ffbc35,
  danger: #b31d09,
  primary: #080575,
  success: #55ce63,
  secondary: #1565c0,
  color: (
    info: #fff,
    dark: #fff,
    light: #000,
    accent: #fff,
    danger: #fff,
    warning: #fff,
    primary: #fff,
    success: #fff,
    secondary: #fff
  ),
  border: (
    light: #000,
    dark: #3c3c3c,
    info: #2491cd,
    warning: #f30,
    accent: #1565c0,
    danger: #d9171e,
    primary: #080575,
    success: #4ba81f,
    secondary: #1565c0
  ),
  disabled: (
    bg: (
      info: #f0f5f5,
      dark: #f0f5f5,
      light: #f0f5f5,
      danger: #f0f5f5,
      accent: #f0f5f5,
      warning: #f0f5f5,
      success: #f0f5f5,
      primary: #f0f5f5,
      secondary: #f0f5f5
    ),
    color: (
      info: #ccd2d2,
      dark: #ccd2d2,
      light: #ccd2d2,
      danger: #ccd2d2,
      accent: #ccd2d2,
      warning: #ccd2d2,
      success: #ccd2d2,
      primary: #ccd2d2,
      secondary: #ccd2d2
    ),
    border: (
      info: #f0f5f5,
      dark: #f0f5f5,
      light: #f0f5f5,
      danger: #f0f5f5,
      accent: #f0f5f5,
      warning: #f0f5f5,
      success: #f0f5f5,
      primary: #f0f5f5,
      secondary: #f0f5f5
    )
  ),
  hover: (
    bg: (
      dark: #000,
      light: #eee,
      info: darken(#2491cd, 6.5%),
      warning: darken(#f30, 6.5%),
      danger: darken(#d9171e, 6.5%),
      accent: darken(#1565c0, 6.5%),
      success: darken(#4ba81f, 6.5%),
      primary: darken(#080575, 6.5%),
      secondary: darken(#1565c0, 6.5%)
    ),
    color: (
      info: #fff,
      dark: #fff,
      light: #222,
      danger: #fff,
      accent: #fff,
      primary: #fff,
      warning: #fff,
      success: #fff,
      secondary: #fff
    ),
    border: (
      light: #000,
      dark: #3c3c3c,
      info: #2491cd,
      warning: #f30,
      accent: #1565c0,
      danger: #d9171e,
      primary: #080575,
      success: #4ba81f,
      secondary: #1565c0
    )
  ),
  lighten: (
    dark: #000,
    light: #fff,
    info: #c5dff7,
    accent: #f8f2ff,
    danger: #ffd2d4,
    primary: #fbf5ff,
    warning: #ffc39c,
    success: #cefff5,
    secondary: #f8f2ff,
    color: (
      dark: #fff,
      info: #2491cd,
      warning: #f30,
      light: #424242,
      accent: #1565c0,
      danger: #d9171e,
      primary: #080575,
      success: #4ba81f,
      secondary: #1565c0
    ),
    hover: (
      bg: (
        dark: #000,
        light: #fff,
        info: #2491cd,
        warning: #f30,
        accent: #1565c0,
        danger: #d9171e,
        success: #4ba81f,
        primary: #080575,
        secondary: #1565c0
      ),
      color: (
        info: #fff,
        dark: #fff,
        light: #000,
        accent: #fff,
        danger: #fff,
        warning: #fff,
        primary: #fff,
        success: #fff,
        secondary: #fff
      )
    )
  ),
  outline: (
    info: transparent,
    dark: transparent,
    light: transparent,
    accent: transparent,
    danger: transparent,
    warning: transparent,
    success: transparent,
    primary: transparent,
    secondary: transparent,
    color: (
      light: #fff,
      dark: #000,
      info: #2491cd,
      warning: #f30,
      accent: #1565c0,
      danger: #d9171e,
      success: #4ba81f,
      primary: #080575,
      secondary: #1565c0
    ),
    border: (
      light: #fff,
      dark: #3c3c3c,
      info: #2491cd,
      warning: #f30,
      accent: #1565c0,
      danger: #d9171e,
      primary: #080575,
      success: #4ba81f,
      secondary: #1565c0
    ),
    hover: (
      bg: (
        dark: #000,
        light: #fff,
        info: #2491cd,
        warning: #f30,
        accent: #1565c0,
        danger: #d9171e,
        success: #4ba81f,
        primary: #080575,
        secondary: #1565c0
      ),
      color: (
        info: #fff,
        dark: #fff,
        light: #000,
        accent: #fff,
        danger: #fff,
        warning: #fff,
        primary: #fff,
        success: #fff,
        secondary: #fff
      )
    ),
    disabled: (
      bg: (
        info: transparent,
        dark: transparent,
        light: transparent,
        accent: transparent,
        danger: transparent,
        warning: transparent,
        success: transparent,
        primary: transparent,
        secondary: transparent
      ),
      color: (
        info: #c9c9c9,
        dark: #c9c9c9,
        light: #c9c9c9,
        accent: #c9c9c9,
        danger: #c9c9c9,
        warning: #c9c9c9,
        success: #c9c9c9,
        primary: #c9c9c9,
        secondary: #c9c9c9
      ),
      border: (
        info: #ccd2d2,
        dark: #ccd2d2,
        light: #ccd2d2,
        accent: #ccd2d2,
        danger: #ccd2d2,
        warning: #ccd2d2,
        success: #ccd2d2,
        primary: #ccd2d2,
        secondary: #ccd2d2
      )
    )
  )
);
$cont: map-get($color, color);
