/**!
 * @name Multiselector
 * @author Ajith S Punalur (ASP)
 * @version 1.1.0
 * @license MIT
 * @date 07-01-2022
 **/

@import "./sumoSelect";

.SelectBox {
  padding: 4px 8px;
}

.SumoSelect {
  cursor: pointer;

  &.fw {
    width: 100%;
  }

  &[role="button"],
  & > .CaptionCont {
    & > span {
      cursor: pointer !important;
    }
  }

  & > .optWrapper.multiple > .options li.opt.selected span i,
  .select-all.selected > span i {
    background-color: cssvar($theme, accent);
  }

  .select-all.partial > span i {
    background-color: #84a091;
  }

  .search-txt {
    height: 100%;
  }

  &.hasIcon {
    &:before {
      left: 0;
      top: 50%;
      z-index: 1;
      width: 1.8em;
      color: inherit;
      margin-top: -0.5em;
      text-align: center;
      position: absolute;
      pointer-events: none;

      @extend .i;
    }

    .search-txt,
    .SelectBox {
      padding-left: 1.7em;
    }
  }

  label {
    margin: 0;
    @include font($fontFamily);
    color: cssvar($theme, text);
  }

  & > .CaptionCont {
    border-radius: 0;
    border-color: transparent;
    & > label {
      margin: 0;
      width: 50px;
      & > i {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: block;
        font-size: 50px;
        line-height: 50px;
        position: absolute;
        text-align: center;
        font-style: normal;
        background-image: none;
        color: cssvar($theme, dark);
        &:before {
          width: 100%;
          content: $i-arrow_drop_down;
          font-family: "#{$icomoon-font-family}";
        }
      }
    }

    & > span {
      min-height: remc(20px);
    }

    span.placeholder {
      font-style: normal;
      background: transparent;
    }
  }

  & > .optWrapper {
    @include border-radius(2px);
    box-shadow: 0px 2px 2px cssvar($color 0.5, dark);

    & > .options {
      max-height: 280px;
      li.opt {
        border-bottom: none;
        &:hover,
        &:focus,
        &:active {
          background: cssvar($color 0.3, accent);
        }
      }
    }
  }

  .select-all {
    height: 35px;
  }

  &:focus {
    & > .CaptionCont {
      box-shadow: 0 0 0 0.2rem cssvar($color 0.25, primary);
    }
  }
  // &:focus,
  // &.open
  &:hover:not(:focus) {
    & > .CaptionCont {
      @include box-shadow(none);
    }
  }

  &.open {
    & > .optWrapper {
      top: 100%;

      &.isFloating {
        top: 0;
      }
    }

    .search-txt {
      border-radius: 0;
    }
  }
}
