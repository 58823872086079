@mixin generate-button-styles($prefix, $map, $key, $appPrefix: app) {
  --#{$prefix}-btn-bg: #{cssvar(($map, $appPrefix), $key)};
  --#{$prefix}-btn-active-shadow: none;
  --#{$prefix}-btn-color: #{cssvar(($map, $appPrefix), color, $key)};
  --#{$prefix}-btn-border-color: #{if(
      map-has-key($map, border),
      #{cssvar(($map, $appPrefix), border, $key)},
      #{cssvar(($map, $appPrefix), $key)}
    )};
  --#{$prefix}-btn-focus-shadow-rgb: #{toRGBList(map-get($map, $key))};
  --#{$prefix}-btn-hover-bg: #{cssvar(($map, $appPrefix), hover, bg, $key)};
  --#{$prefix}-btn-hover-color: #{cssvar(($map, $appPrefix), hover, color, $key)};
  --#{$prefix}-btn-hover-border-color: #{if(
      map-has-key($map, hover, border),
      #{cssvar(($map, $appPrefix), hover, border, $key)},
      #{cssvar(($map, $appPrefix), hover, bg, $key)}
    )};
  --#{$prefix}-btn-active-bg: #{if(
      map-has-key($map, active, bg),
      #{cssvar(($map, $appPrefix), active, bg, $key)},
      #{cssvar(($map, $appPrefix), hover, bg, $key)}
    )};
  --#{$prefix}-btn-active-color: #{if(
      map-has-key($map, active, color),
      #{cssvar(($map, $appPrefix), active, color, $key)},
      #{cssvar(($map, $appPrefix), hover, color, $key)}
    )};
  --#{$prefix}-btn-active-border-color: #{if(
      map-has-key($map, active, border),
      #{cssvar(($map, $appPrefix), active, border, $key)},
      #{cssvar(($map, $appPrefix), hover, bg, $key)}
    )};
  --#{$prefix}-btn-disabled-bg: var(--#{$prefix}-btn-bg);
  --#{$prefix}-btn-disabled-color: var(--#{$prefix}-btn-color);
  --#{$prefix}-btn-disabled-border-color: var(--#{$prefix}-btn-border-color);
}

@mixin generate-disabled-styles($prefix, $key, $map, $appPrefix: app) {
  --#{$prefix}-btn-color: #{if(
      map-has-key($map, disabled, color),
      #{cssvar(($map, $appPrefix), disabled, color, $key)},
      #{cssvar(($map, $appPrefix), $key)}
    )};
  --#{$prefix}-btn-bg: #{if(
      map-has-key($map, disabled, bg),
      #{cssvar(($map, $appPrefix), disabled, bg, $key)},
      #{cssvar(($map, $appPrefix), $key)}
    )};
  --#{$prefix}-btn-border-color: #{if(
      map-has-key($map, disabled, border),
      #{cssvar(($map, $appPrefix), disabled, border, $key)},
      #{cssvar(($map, $appPrefix), $key)}
    )};
  --#{$prefix}-btn-disabled-bg: var(--#{$prefix}-btn-bg);
  --#{$prefix}-btn-disabled-color: var(--#{$prefix}-btn-color);
  --#{$prefix}-btn-disabled-border-color: var(--#{$prefix}-btn-border-color);
}

@mixin button-variant($prefix, $key, $color, $appPrefix: app) {
  &-#{$key} {
    @include generate-button-styles($prefix, $color, $key, $appPrefix);

    // &:disabled,
    // &.disabled {
    //   @include generate-disabled-styles($prefix, $key, $color, $appPrefix);
    // }

    &._lighten {
      $props: map-get($color, lighten);
      @include generate-button-styles($prefix, $props, $key, #{$appPrefix}-lighten);
    }

    &.btn-link {
      --#{$prefix}-btn-color: #{map-get($color, $key)};
      --#{$prefix}-btn-bg: transparent;
      --#{$prefix}-btn-border-color: transparent;
      --#{$prefix}-btn-hover-bg: transparent;
      --#{$prefix}-btn-hover-border-color: transparent;
      --#{$prefix}-btn-hover-color: #{map-get($color, hover, bg, $key)};
      --#{$prefix}-btn-active-color: #{if(
          map-has-key($color, active, color),
          #{cssvar(($color), active, color, $key)},
          #{cssvar(($color), $key)}
        )};
      --#{$prefix}-btn-active-bg: transparent;
      --#{$prefix}-btn-active-border-color: transparent;
      text-decoration: underline;
    }
  }

  &-outline-#{$key} {
    $props: map-get($color, outline);
    @include generate-button-styles($prefix, $props, $key, #{$appPrefix}-outline);

    // &:disabled,
    // &.disabled {
    //   --#{$prefix}-btn-color: #{if(
    //       map-has-key($color, outline, disabled, color),
    //       #{cssvar(($color), outline, disabled, color, $key)},
    //       #{cssvar(($color), outline, $key)}
    //     )};
    //   --#{$prefix}-btn-bg: #{if(
    //       map-has-key($color, outline, disabled, bg),
    //       #{cssvar(($color), outline, disabled, bg, $key)},
    //       #{cssvar(($color), outline, $key)}
    //     )};
    //   --#{$prefix}-btn-border-color: #{if(
    //       map-has-key($color, outline, disabled, border),
    //       #{cssvar(($color), outline, disabled, border, $key)},
    //       #{cssvar(($color), outline, $key)}
    //     )};
    // }
  }
}
