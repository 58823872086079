swiper {
  &-container {
    width: 100%;
  }
  &-slide {
    background-size: cover;
    background-position: center;
    img {
      width: 100%;
      display: block;
    }
  }
}

.gallerySwiper {
  &::part(container) {
    --swiper-navigation-sides-offset: 16px;
  }
  &::part(button-prev),
  &::part(button-next) {
    font-family: cssvar($theme, iconFont);
  }
}
