.popup {
  &.popupVideo {
    background: transparent;

    .popHeader {
      padding: 0;
      z-index: 2;
      min-height: 0;
      overflow: visible;
      position: absolute;
      border: 0 none transparent;
      .close {
        right: initial;
        display: flex;
        top: initial;
        padding: 3px;
        line-height: 1;
        margin-left: auto;
        width: remc(36px);
        height: remc(36px);
        position: relative;
        align-items: center;
        min-width: remc(36px);
        font-size: remc(24px);
        justify-content: center;
      }
    }

    .popContent {
      padding: 0;
      align-items: center;
      justify-content: center;
    }

    .plyr {
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: 768px) {
      .popHeader {
        .close {
          background: transparent;
        }
      }
    }

    @media only screen and (max-width: 960px) and (max-height: 640px) {
      .popHeader {
        .close {
          right: 0;
          transform: translateY(100%);
          background: cssvar($theme 0.7, color);
        }
      }
    }
  }
}
