@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}

// @include scrollbar(.5em, slategray);
@mixin scrollbar($size, $fgColor, $bgColor: mix($fgColor, white, 50%), $radius: 12px) {
  // For Chromium Browsers
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  // /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background-color: $fgColor;
    // /* Handle on hover */
    &:hover {
      @if type-of($fgColor) == list {
        background: darken(nth($fgColor, 1), 6.5%);
      } @else {
        background: rgba($fgColor, 0.9); // darken($fgColor, 6.5%);
      }
    }
  }
  // /* Track */
  &::-webkit-scrollbar-track {
    margin: 0 4px;
    background: $bgColor;
    border-radius: $radius;
  }

  &,
  * {
    // IE
    scrollbar-face-color: $fgColor;
    scrollbar-track-color: $bgColor;

    // Mozilla Firefox
    scrollbar-width: thin;
    scrollbar-color: $fgColor $bgColor;
  }
}
