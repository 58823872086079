// /* -------- ANIMATIONS -------- */
$vendor: "-moz-", "-webkit-", "-ms-", "";

///*@mixin saveAnimation($animateName:"animationName") {
//	@each $i in $vendor {
//		#{$i}keyframes #{$animateName} {
//			@content;
//		}
//	}
//}*/

@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: #{$value};
    }
    // Output standard non-prefixed declaration
    #{$property}: #{$value};
  }
}
// /* [EG] @include prefix((column-count: 3,column-gap: 1.5em,column-rule: 2px solid hotpink), webkit moz);
// [copy]//  @include prefix(( X:X,X:X ), webkit moz);
// */

@mixin animate($animateName, $duration: 0.3s, $loop: 1, $timing: ease-in) {
  @each $prefix in $vendor {
    #{$prefix}animation-name: $animateName;
    #{$prefix}animation-iteration-count: $loop;
    #{$prefix}animation-timing-function: $timing;
    #{$prefix}animation-duration: $duration;
  }
}
// /*@include animate(slideUp, 0.3s, 1, ease-in);*/

@mixin animation($animate...) {
  $max: length($animate);
  $animations: "";

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  @include prefix(
    (
      animation: $animations
    ),
    webkit moz o
  );
} // @include animation('animationName 1s 1'[, 'animationName2 1s 1 1s']);

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
} //@include keyframes(animationName) {0% { x: x; }100% { x: x;} }
