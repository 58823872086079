/**!
 *	POPUP Plugin v5.3.2-beta
 *	@author: Ajith S Punalur (ASP) - Suryadarsan
 *  @date: 27-02-2023 (DD-MM-YYYY)
 *  @licence: MIT
**/

$gutter: 40px !default;
$DARK: #000 !default;
$LIGHT: #fff !default;
$popBg: #fff !default;
$popTxt: #333 !default;
$popBrdr: #ccc !default;
$headTxt: inherit !default;

html,
body {
  &.modalOpen {
    position: relative;
    overflow: hidden !important;

    .ui-datepicker {
      z-index: 1100 !important;
    }
  }
}

.absFC {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.loading {
  z-index: 9999;
  @extend .absFC !optional;
  background: rgba($LIGHT, 0.9);
}

.modalOverlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  background: rgba($DARK, 0);

  // display: none;
  // @extend %animateFast;
  &.none,
  &.transparent {
    background: none transparent !important;

    &>* {
      pointer-events: all;
    }

    &>.popup {
      @include box-shadow(0px 0px 30px rgba($DARK, 0.8));
    }
  }

  &.none {
    pointer-events: none;

    * {
      pointer-events: all;
    }
  }

  &.inlineMock {
    position: absolute;

    // position: fixed;
    // overflow-y: auto;
    // max-height: 100vh;
    .popup {
      max-height: initial;
      position: relative;
      position: sticky;
    }
  }

  &.active {
    display: flex;
    visibility: visible;
    background: rgba($DARK, 0.54);

    &:not(.inlineMock) {
      align-items: center;
      justify-content: center;

      .popup {
        position: relative;
        // &.open {
        //   top: auto !important;
        //   left: auto !important;
        // }
      }
    }
  }
}

.popMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup {
  $popHgt: 50%;
  $popWdth: 50%;
  z-index: 1000;
  display: none;
  color: $popTxt;
  width: $popWdth;
  height: $popHgt;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
  background: $popBg;
  flex-direction: column;
  animation-fill-mode: both;
  animation-duration: 0.84s;
  animation-duration: var(--animate-duration, 0.84s);

  &.open {
    display: flex;
  }

  &>.row {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }

  .popHeader,
  .popFooter,
  .popContent {
    width: 100%;
    display: flex;
  }

  .popFooter,
  .popHeader {
    flex-grow: 0;
    min-height: 40px;
    position: relative;
    flex-direction: row;
    padding: 10px 15px 0;
  }

  .popHeader {
    color: $headTxt;
    font-size: 16px;
    border-bottom: cssvar($theme, brdr) 1px solid;

    // &.lsBtn {
    //   padding-left: $gutter;
    // }

    // &.rsBtn {
    //   padding-right: $gutter;
    // }

    // &.xsBtn {
    //   padding-left: $gutter;
    //   padding-right: $gutter;
    // }

    .close {
      top: 0px;
      right: 0px;
      opacity: 1;
      padding: 1px;
      color: #fff;
      display: block;
      width: $gutter;
      height: $gutter;
      cursor: pointer;
      font-size: 30px;
      min-width: $gutter;
      text-align: center;
      position: absolute;
      font-weight: normal;
      text-decoration: none;
      border: none transparent;
      line-height: $gutter - 4;
      background: cssvar($theme, color);
    }

    .btn.back {
      top: 0;
      left: 0;
      width: $gutter;
      height: $gutter;
      font-size: 1.7em;
      position: absolute;

      &:hover,
      &:focus,
      &:active {
        background: rgba($DARK, 0.12);
      }
    }
  }

  .popFooter {
    padding: 5px 15px;
    border-top: cssvar($theme, brdr) 1px solid;
  }

  .popContent {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    flex-direction: column;

    .fWHWrapper,
    .iframe {
      top: 0px;
      left: 0px;
      z-index: 1;
      right: 0px;
      bottom: 0px;
      position: absolute;
      width: 100% !important;
      height: 100% !important;
    }
  }

  iframe.popContent {
    width: 100%;
  }

  &[data-xsource="true"] {
    .popContent {
      position: relative;

      iframe {
        @extend .fWHWrapper !optional;
      }
    }
  }

  &[data-loader],
  &[data-loader="true"] {
    .popContent {
      overflow: hidden;
      position: relative;

      &:after,
      &:before {
        pointer-events: all;
      }

      &:before {
        @extend .loading !optional;
        background-color: rgba($LIGHT, 0.8);
      }

      &:after {
        top: 50%;
        left: 50%;
        content: "";
        z-index: 1000;
        @extend .loader;
        position: absolute;
      }
    }
  }

  &[data-loader=""],
  &[data-loader="false"] {
    .popContent {
      overflow: auto;

      &:after,
      &:before {
        display: none;
      }
    }
  }

  &.rotate {
    &XIn {
      @include animation("rotateXIn 500ms 1 10ms");
    }

    &XOut {
      @include animation("rotateXOut 500ms 1 10ms");
    }

    &YIn {
      @include animation("rotateYIn 500ms 1 10ms");
    }

    &YOut {
      @include animation("rotateYOut 500ms 1 10ms");
    }
  }

  &.asymmetricScale {

    &In,
    &Out {

      &,
      .popContent {
        overflow: hidden !important;
      }
    }

    &In {
      @include animation("asymmetricScaleIn 2s 1 10ms");
    }

    &Out {
      @include animation("asymmetricScaleOut 500ms 1 0ms");
    }
  }
}