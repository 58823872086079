// @import "./scss/globalStyles";

// table {
//     thead {
//         tr {
//             th {
//                 color: #ffffff;
//                 background: #0b109f;
//                 font-size: 14px;
//             }
//         }
//     }

//     tbody {
//         tr {
//             td {
//                 background: #fff;
//                 color: #495057;
//                 transition: box-shadow .2s;
//                 outline-color: #a6d5fa;
//             }

//             &:nth-child(even) {
//                 background: #e9ecef;
//                 color: #495057;
//             }
//         }
//     }
// }

// .p-paginator .p-paginator-current {
//     background-color: transparent;
//     border: 0 none;
//     color: #6c757d;
//     min-width: 2.357rem;
//     height: 2.357rem;
//     margin: 0.143rem;
//     padding: 0 0.5rem;
// }

// .p-paginator .p-paginator-pages .p-paginator-page {
//     background-color: transparent;
//     border: 0 none;
//     color: #6c757d;
//     min-width: 2.357rem;
//     height: 2.357rem;
//     margin: 0.143rem;
//     transition: box-shadow .2s;
//     border-radius: 2px;
// }

// .button-theme {
//     background-color: #16006f;
//     color: #fff;
//     border: none;
//     height: 36px;
//     width: 100px;
// }

// .button-theme-clear {
//     color: #16006f;
//     background-color: #DDE2EA;
//     border: none;
//     height: 36px;
//     width: 100px;
// }

// .bg-active-primary {
//     background-color: #009efb;
//     color: #DDE2EA;
//     width: 80px;
//     height: 25px;
//     display: flex;
//     justify-content: center;
//     border-radius: 10px;
//     align-items: center;
// }

// .btn-round {
//     border-radius: 50%;
//     width: 35px;
//     height: 35px;
//     font-size: 18px;
//     text-align: center;
//     padding: 7px;
// }

// .p-checkbox-label {
//     margin-left: 0.5rem;
// }

@import "./scss/globalStyles";
@import "~@ng-select/ng-select/themes/default.theme.css";

table {
    thead {
        tr {
            th {
                color: #ffffff;
                background: #0b109f;
                font-size: 14px;
                line-height: 23px;
                font-weight: 600;
            }
        }
    }

    tbody {
        tr {
            td {
                // background: #fff;
                color: cssvar($theme, textL);
                transition: box-shadow 0.2s;
                outline-color: #a6d5fa;
                font-size: 16px;
            }

            &:nth-child(even) {
                background: cssvar($theme, bgD);
            }

            &:nth-child(odd) {
                background: cssvar($theme, write);
            }
        }
    }
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 2px;
}

.button-theme {
    background-color: #16006f;
    color: #fff;
    border: none;
    height: 36px;
    width: 100px;
}

.button-theme-clear {
    color: #16006f;
    background-color: #dde2ea;
    border: none;
    height: 36px;
    width: 100px;
}

// For table status
.bg-active-primary {
    background-color: #009efb;
    color: #dde2ea;
    width: 75px;
    height: 25px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
}

.bg-active-danger {
    background-color: #ca240e;
    color: #dde2ea;
    width: 75px;
    height: 25px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
}

.btn-round {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.p-checkbox-label {
    margin-left: 0.5rem;
}

f-error {
    font-size: 13px;
    color: red;
    line-height: 0;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-first,
.p-paginator-prev,
.p-paginator-current {
    background: transparent;
    border: none;
    padding: 10px;
}

.p-paginator-bottom {
    padding: 0px 40px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr:nth-child(even) {
    background: #eff1f5;
}

.p-datatable {
    .p-sortable-column {
        font-size: 13px;
        padding: 0.3em;

        .p-sortable-column-icon {
            width: 15px;
            height: 15px;
            padding-left: 5px;
            color: white;
        }
    }

    @media only screen and (max-width: 1200px) {
        .p-sortable-column {
            .p-sortable-column-icon {
                padding-left: 2px;
                width: 11px;
                height: 11px;
            }
        }
    }
}

.search-container {
    position: relative;

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        input[type="text"] {
            width: 100%;
            padding-right: 2.5rem;
            border: 1px solid #0b109f;
            border-radius: 20px;
            padding-left: 10px;
            height: 36px;
            font-size: 14px;
            outline: none;
            transition: border-color 0.3s ease;

            &:focus {
                border-color: #007bff;
            }
        }

        .search-icon {
            position: absolute;
            right: 23px;
            font-size: 1.2rem;
            color: #aaa;
            pointer-events: none;
        }
    }
}

.validation {
    padding-top: 3px;
    font-size: 16px;
    color: red;
    line-height: 0;
}

f-error {
    font-size: 16px;
    color: red;
    line-height: 0;
}
