@use "sass:map";

header.header {

  // color: cssvar($theme, header, text);
  // background: cssvar($theme, header, bg);
  .dropdown {
    &-item {
      @include font($font);
    }

    &-menu {
      @extend %animateFast;

      & {
        --bs-dropdown-padding-x: 0;
        --bs-dropdown-padding-y: 0.5rem;
        --bs-dropdown-font-size: 1rem;
        --bs-dropdown-spacer: 0.125rem;
        --bs-dropdown-min-width: 10rem;
        --bs-dropdown-border-radius: 10px;
        --bs-dropdown-item-padding-x: 20px;
        --bs-dropdown-item-padding-y: 10px;
        --bs-dropdown-border-color: transparent;
        --bs-dropdown-bg: #{cssvar($theme, header, dd, bg)};
        --bs-dropdown-color: #{cssvar($theme, header, dd, color)};
        --bs-dropdown-link-color: #{cssvar($theme, header, dd, color)};
        --bs-dropdown-link-hover-bg: #{cssvar($theme, header, dd, hover, bg)};
        --bs-dropdown-link-active-bg: #{cssvar($theme, header, dd, active, bg)};
        --bs-dropdown-link-hover-color: #{cssvar($theme, header, dd, hover, color)};
        --bs-dropdown-link-active-color: #{cssvar($theme, header, dd, active, color)};
        // --bs-dropdown-zindex: 1000;
        // --bs-dropdown-border-width: 1px;
        // --bs-dropdown-header-color: #6c757d;
        // --bs-dropdown-header-padding-x: 1rem;
        // --bs-dropdown-header-padding-y: 0.5rem;
        // --bs-dropdown-divider-margin-y: 0.5rem;
        // --bs-dropdown-link-disabled-color: #adb5bd;
        // --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
        // --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
        // --bs-dropdown-box-shadow: 0px 3px 6px #{cssvar($color 0.16, dark)};
      }

      min-width: 100%;
      box-shadow: var(--bs-dropdown-box-shadow);
    }
  }

  .navbar {
    & {
      --bs-navbar-brand-color: cssvar($theme, color);
    }

    .nav-link {
      line-height: 1em;
      @extend %animateFast;
      @include font($font);
      font-size: 20px;

      // & > span {
      //   position: relative;
      //   &:before {
      //     left: 0;
      //     right: 0;
      //     width: 0%;
      //     height: 1px;
      //     content: "";
      //     opacity: 0.5;
      //     margin: auto;
      //     bottom: -10px;
      //     position: absolute;
      //     @extend %animateFast;
      //   }
      // }
      .user-img {
        margin-right: 10px;
      }
    }

    .nav-item {
      & {
        --bs-nav-link-font-size: #{map.get($theme, baseFontSize)};
      }

      // &:not(:last-child) {
      //   border-right: 1px solid cssvar($theme, header, brdr);
      // }
      // &.hasBtn {
      //   .nav-link {
      //     color: #{cssvar($theme, header, link, color)};
      //   }
      // }
    }

    &-nav {
      & {
        --bs-nav-link-padding-x: 0;
        --bs-nav-link-padding-y: 14.5px;
        --bs-navbar-nav-link-padding-x: 24px;
        --bs-nav-link-color: #{cssvar($theme, header, link, color)};
        --bs-navbar-active-color: #{cssvar($theme, header, link, active)};
        --bs-nav-link-hover-color: #{cssvar($theme, header, link, hover)};
        // --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
      }

      &.justify-content-end {
        .dropdown-menu {
          &[data-bs-popper] {
            right: 0;
            left: auto;
          }
        }
      }

      .badge {
        & {
          --bs-badge-font-size: 10px;
        }

        position: absolute;
        right: 7px;
        top: 4px;
      }
    }

    &-light {
      .navbar-nav {
        --bs-nav-link-color: #{cssvar($theme, color)};
        --bs-navbar-active-color: #{cssvar($theme, color)};
      }
    }
  }

  .navbar-toggler {
    border: 0;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-toggler-icon {
    background-image: none;
    color: cssvar($theme, write);
    width: auto;
    height: auto;
  }

  .offcanvas {
    .offcanvas-body {
      .onlyForMobile {
        display: none;
      }
    }

  }

  @include media-breakpoint-down(lg) {
    .offcanvas {
      & {
        --bs-offcanvas-bg: #{cssvar($theme, header, offcanvas, bg)};
        --bs-offcanvas-color: #{cssvar($theme, header, offcanvas, text)};
      }

      .offcanvas-title {
        color: cssvar($theme, text);
        font-size: 20px;

        .user {
          .user-img {
            margin-right: 10px;
          }
        }
      }

      .offcanvas-body {
        .nav-item {
          &.has-arrow {
            display: none;
          }

          .onlyForMobile {
            display: inline-block;
          }

          .text {
            margin-left: 10px;
            font-size: 18px;
          }

          .nav-link {
            i {
              vertical-align: middle;
            }
          }
        }

        .navbar-nav {
          .badge {
            top: 18px;
          }
        }

        ul.onlyForMobile {
          display: block;
          padding-left: 0;

          li {
            @include notaList;
          }
        }
      }



      .navbar-nav {
        & {
          --bs-nav-link-color: #{cssvar($theme, header, offcanvas, link)};
          --bs-navbar-active-color: #{cssvar($theme, header, offcanvas, active, link)};
          --bs-nav-link-hover-color: #{cssvar($theme, header, offcanvas, active, link)};
        }
      }
    }

    .navbar {
      .navbar-brand {
        margin: auto;
      }
    }
  }
}