.plyr {
  --plyr-color-main: #{cssvar($theme, color)};
  --plyr-video-background: #{cssvar($color, dark)};
  // --plyr-focus-visible-color: var(--plyr-color-main);
  --plyr-badge-background: #{cssvar($color, accent)};
  --plyr-badge-text-color: #{cssvar($cont app-color, accent)};
  --plyr-badge-border-radius: #{cssvar($global, border-radius)};
  --plyr-captions-background: #{cssvar($color 0.8, dark)};
  --plyr-captions-text-color: #{cssvar($color, light)};
  --plyr-control-icon-size: #{cssvar($theme, baseFontSize)};
  // --plyr-control-spacing: 10px;
  --plyr-control-padding: calc(var(--plyr-control-spacing) * 0.7);
  --plyr-control-radius: #{cssvar($theme, border-radius-lg)};
  --plyr-control-toggle-checked-background: var(--plyr-color-main);
  --plyr-video-controls-background: linear-gradient(cssvar($color 0, dark), cssvar($color 0.75, dark));
  --plyr-video-control-color: #{cssvar($color, light)};
  --plyr-video-control-color-hover: #{cssvar($color, light)};
  --plyr-video-control-background-hover: var(--plyr-color-main);
  --plyr-audio-controls-background: #{cssvar($color, light)};
  --plyr-audio-control-color: #{cssvar($color, accent)};
  --plyr-audio-control-color-hover: #{cssvar($color, light)};
  --plyr-audio-control-background-hover: var(--plyr-color-main);
  --plyr-menu-background: #{cssvar($color 0.9, light)};
  --plyr-menu-color: #{cssvar($theme, text)};
  --plyr-menu-shadow: 0 1px 2px cssvar($color 0.15, dark);
  --plyr-menu-radius: #{cssvar($theme, border-radius-lg)};
  --plyr-menu-arrow-size: 6px;
  --plyr-menu-item-arrow-color: #{cssvar($theme, text)};
  --plyr-menu-item-arrow-size: 4px;
  --plyr-menu-border-color: #{cssvar($theme, brdr)};
  --plyr-menu-border-shadow-color: #{cssvar($color, light)};
  --plyr-progress-loading-size: 25px;
  --plyr-progress-loading-background: #{cssvar($theme 0.6, accent)};
  --plyr-video-progress-buffered-background: #{cssvar($color 0.25, light)};
  --plyr-audio-progress-buffered-background: #{cssvar($theme 0.6, accent)};
  --plyr-range-thumb-height: 13px;
  --plyr-range-thumb-background: #{cssvar($color, light)};
  --plyr-range-thumb-shadow: 0 1px 1px rgba(215, 26, 18, 0.15), 0 0 0 1px rgba(215, 26, 18, 0.2);
  --plyr-range-thumb-active-shadow-width: 3px;
  --plyr-range-track-height: 5px;
  --plyr-range-fill-background: var(--plyr-color-main);
  --plyr-video-range-track-background: var(--plyr-video-progress-buffered-background);
  --plyr-video-range-thumb-active-shadow-color: #{cssvar($color 0.5, light)};
  --plyr-audio-range-track-background: var(--plyr-video-progress-buffered-background);
  --plyr-audio-range-thumb-active-shadow-color: #{cssvar($theme 0.1, accent)};
  --plyr-tooltip-background: #{cssvar($color 0.9, light)};
  --plyr-tooltip-color: #{cssvar($theme, text)};
  --plyr-tooltip-padding: calc(var(--plyr-control-spacing) / 2);
  --plyr-tooltip-arrow-size: 4px;
  --plyr-tooltip-radius: 3px;
  --plyr-tooltip-shadow: 0 1px 2px cssvar($color 0.15, dark);
  // --plyr-font-family: #{cssvar($theme, font)};
  --plyr-font-size-base: #{cssvar($theme, baseFontSize)};
  --plyr-font-size-small: 13px;
  --plyr-font-size-large: 18px;
  --plyr-font-size-xlarge: 21px;
  --plyr-font-size-time: var(--plyr-font-size-small);
  --plyr-font-size-menu: var(--plyr-font-size-small);
  --plyr-font-size-badge: 9px;
  --plyr-font-weight-bold: #{$fontM};
  --plyr-font-weight-regular: #{$font};
  --plyr-line-height: 1.7;
  // --plyr-font-smoothing: false;
}
