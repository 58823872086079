$themeColor: cssvar($theme, color);

@import "../../../node_modules/swiper/swiper";

@import "../../../node_modules/swiper/modules/a11y";
@import "../../../node_modules/swiper/modules/autoplay";
@import "../../../node_modules/swiper/modules/controller";
// @import "../../../node_modules/swiper/modules/effect-cards";
// @import "../../../node_modules/swiper/modules/effect-coverflow";
// @import "../../../node_modules/swiper/modules/effect-creative";
// @import "../../../node_modules/swiper/modules/effect-cube";
@import "../../../node_modules/swiper/modules/effect-fade";
// @import "../../../node_modules/swiper/modules/effect-flip";
@import "../../../node_modules/swiper/modules/free-mode";
@import "../../../node_modules/swiper/modules/grid";
@import "../../../node_modules/swiper/modules/hash-navigation";
@import "../../../node_modules/swiper/modules/history";
@import "../../../node_modules/swiper/modules/keyboard";
@import "../../../node_modules/swiper/modules/manipulation";
@import "../../../node_modules/swiper/modules/mousewheel";
@import "../../../node_modules/swiper/modules/navigation";
@import "../../../node_modules/swiper/modules/pagination";
@import "../../../node_modules/swiper/modules/parallax";
@import "../../../node_modules/swiper/modules/scrollbar";
@import "../../../node_modules/swiper/modules/thumbs";
@import "../../../node_modules/swiper/modules/virtual";
@import "../../../node_modules/swiper/modules/zoom";

.swiper {
  width: 100%;
  max-height: 100vh;
}
